import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { useDidMount } from '@mtsbank/ui-kit'
import { getQuestionnaire } from '@utils/getNps'
import { uuid } from '@utils/generateUuid'
import { Timer } from '@utils/timer'

const NPS_DOWNLOAD_DELAY = 90000 // 90 seconds in ms
const NPS_DELAY = 120000 // 2 minutes in ms
const timer = new Timer(NPS_DELAY)

export const NpsLoader = () => {
  const [opened, setOpened] = useState(false)

  const Nps = dynamic(() => import('@components/Nps/Nps').then((imported) => imported.Nps), {
    ssr: false,
  })

  useDidMount(() => {
    const timer = setTimeout(() => getQuestionnaire(), NPS_DOWNLOAD_DELAY)

    return () => {
      clearTimeout(timer)
    }
  })

  useDidMount(() => {
    timer.start(() => setOpened(true))

    sessionStorage.setItem('sessionStartTime', String(+new Date()))

    const feedbackSession = localStorage.getItem('feedback-session')

    if (!feedbackSession) {
      localStorage.setItem('feedback-session', uuid())
    }

    return () => {
      timer.clearTimeout()
    }
  })

  return opened && <Nps />
}
