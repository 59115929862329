import {
  events,
  eventCategories,
  sendGmtEvent,
  eventActions,
  eventLabels,
  screenNames,
  actionGroups,
} from '@utils/gtm/baseFunctions'
import { BottomButtonParams } from '@components/Onboarding/types'
import { LoadType, OnboardingGtmFunction } from './types'

export const baseDataLayerOnboarding = {
  event: events.mtsOnboarding,
  eventCategory: eventCategories.onboarding,
  prCat: eventCategories.netKategorii,
  productId: 'nan',
  productName: 'personalizaciya',
  pageType: screenNames.newMain,
  screenName: typeof window !== 'undefined' ? window.location.href : '',
  ref: typeof window !== 'undefined' ? window.document.referrer : '',
}

export const onboardingLoadGtm: OnboardingGtmFunction<{
  onboarding_alias: string
  download_status: boolean
  load_type: LoadType
}> = (touchPoint, offerId, customParams) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.screenLoad,
      eventLabel: eventLabels.onboardingLoad,
      actionGroup: actionGroups.nonInteractions,
      intId: offerId,
      touchPoint,
      CD1: JSON.stringify(customParams),
    },
  })
}

export const onboardingLoadErrorGtm: OnboardingGtmFunction<{
  error_code: string
  error_title: string
}> = (touchPoint, offerId, customParams) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.errorShow,
      eventLabel: eventLabels.onboardingLoadError,
      actionGroup: actionGroups.nonInteractions,
      intId: offerId,
      touchPoint,
      CD1: JSON.stringify({
        ...{ onboarding_alias: 'NONE', download_status: 'failure', load_type: LoadType.FULLSCREEN },
        ...customParams,
      }),
    },
  })
}

export const onboardingSlideShowGtm: OnboardingGtmFunction<{
  onboarding_alias: string
  slide_alias: string
  slide_position: number
  image_download_status: string
  load_type: LoadType
}> = (touchPoint, offerId, customParams) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.screenShow,
      eventLabel: eventLabels.ekran,
      actionGroup: actionGroups.nonInteractions,
      intId: offerId,
      touchPoint,
      CD1: JSON.stringify(customParams),
    },
  })
}

export const onboardingSlideButtonClickGtm: OnboardingGtmFunction<{
  onboarding_alias: string
  slide_alias: string
  action: BottomButtonParams['action']
  deeplink: string
  load_type: LoadType
  button_name: string
}> = (touchPoint, offerId, customParams) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.buttonClick,
      actionGroup: actionGroups.interactions,
      intId: offerId,
      touchPoint,
      CD1: JSON.stringify(customParams),
    },
  })
}

export const onboardingCloseClickGtm: OnboardingGtmFunction<{
  onboarding_alias: string
  slide_alias: string
  load_type: LoadType
}> = (touchPoint, offerId, customParams) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.buttonClick,
      eventLabel: eventLabels.krestik,
      actionGroup: actionGroups.interactions,
      intId: offerId,
      touchPoint,
      CD1: JSON.stringify(customParams),
    },
  })
}

// TODO: Доработать в следующей итерации
export const onboardingPageChangeClickGtm: OnboardingGtmFunction<{
  onboarding_alias: string
  slide_alias: string
  time_on_slide: string
  way_of_transition: string
  load_type: LoadType
  tap_place: string
}> = (touchPoint, offerId, customParams) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.elementSwipe,
      eventLabel: eventLabels.slider,
      actionGroup: actionGroups.interactions,
      intId: offerId,
      touchPoint,
      CD1: JSON.stringify(customParams),
    },
  })
}

export const onboardingControlGroupCheckGtm: OnboardingGtmFunction<undefined> = (touchPoint) => {
  sendGmtEvent({
    ...baseDataLayerOnboarding,
    ...{
      eventAction: eventActions.screenHide,
      eventLabel: eventLabels.ekran,
      actionGroup: actionGroups.nonInteractions,
      touchPoint,
      CD1: JSON.stringify({
        onboarding_alias: 'NONE',
        reason: 'Control group',
        load_type: LoadType.FULLSCREEN,
      }),
    },
  })
}
