// tslint:disable
/**
 * dbo-loan-issue
 * MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AdditionalButton
 */
export interface AdditionalButton {
  /**
   *
   * @type {string}
   * @memberof AdditionalButton
   */
  text?: string
  /**
   *
   * @type {AdditionalButtonConfirmation}
   * @memberof AdditionalButton
   */
  confirmation?: AdditionalButtonConfirmation
}
/**
 *
 * @export
 * @interface AdditionalButtonConfirmation
 */
export interface AdditionalButtonConfirmation {
  /**
   *
   * @type {string}
   * @memberof AdditionalButtonConfirmation
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof AdditionalButtonConfirmation
   */
  subtitle?: string
  /**
   *
   * @type {string}
   * @memberof AdditionalButtonConfirmation
   */
  buttonURL?: string
  /**
   *
   * @type {string}
   * @memberof AdditionalButtonConfirmation
   */
  buttonText?: string
}
/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  countryName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  countryCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  regionSocr?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  districtName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  localityName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  cityName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  streetName?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  house?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  building?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  block?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  flat?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipCode?: string
  /**
   *
   * @type {string}
   * @memberof Address
   */
  addr?: string
}
/**
 *
 * @export
 * @interface BoxListRes
 */
export interface BoxListRes {
  /**
   *
   * @type {string}
   * @memberof BoxListRes
   */
  descriptionCreditGoal?: string
  /**
   *
   * @type {Array<Boxes>}
   * @memberof BoxListRes
   */
  boxes?: Array<Boxes>
  /**
   *
   * @type {string}
   * @memberof BoxListRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface Boxes
 */
export interface Boxes {
  /**
   *
   * @type {string}
   * @memberof Boxes
   */
  boxSysName?: string
  /**
   *
   * @type {string}
   * @memberof Boxes
   */
  boxName?: string
  /**
   *
   * @type {string}
   * @memberof Boxes
   */
  shortDescription?: string
  /**
   *
   * @type {string}
   * @memberof Boxes
   */
  fullDescription?: string
  /**
   *
   * @type {Array<Sets>}
   * @memberof Boxes
   */
  setsList?: Array<Sets>
}
/**
 *
 * @export
 * @interface Button
 */
export interface Button {
  /**
   *
   * @type {string}
   * @memberof Button
   */
  buttonName?: string
  /**
   *
   * @type {boolean}
   * @memberof Button
   */
  available?: boolean
  /**
   *
   * @type {boolean}
   * @memberof Button
   */
  main?: boolean
  /**
   *
   * @type {string}
   * @memberof Button
   */
  callbackText?: string
}
/**
 *
 * @export
 * @interface CalcBoxes
 */
export interface CalcBoxes {
  /**
   *
   * @type {string}
   * @memberof CalcBoxes
   */
  boxSysName: string
  /**
   *
   * @type {boolean}
   * @memberof CalcBoxes
   */
  flag: boolean
  /**
   *
   * @type {number}
   * @memberof CalcBoxes
   */
  price: number
}
/**
 *
 * @export
 * @interface CancelIssueReq
 */
export interface CancelIssueReq {
  /**
   *
   * @type {string}
   * @memberof CancelIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof CancelIssueReq
   */
  rboId?: number
  /**
   *
   * @type {number}
   * @memberof CancelIssueReq
   */
  requestId: number
}
/**
 *
 * @export
 * @interface CancelIssueRes
 */
export interface CancelIssueRes {
  /**
   *
   * @type {string}
   * @memberof CancelIssueRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface CheckIssueReq
 */
export interface CheckIssueReq {
  /**
   *
   * @type {string}
   * @memberof CheckIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof CheckIssueReq
   */
  rboId?: number
}
/**
 *
 * @export
 * @interface CheckIssueRes
 */
export interface CheckIssueRes {
  /**
   *
   * @type {string}
   * @memberof CheckIssueRes
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof CheckIssueRes
   */
  issueId?: number
}
/**
 *
 * @export
 * @interface ClientData
 */
export interface ClientData {
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  flagAllowPassPFR?: boolean
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  clientName?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  family?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  fatherName?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  mobilePhone?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  extraPhone?: string
  /**
   *
   * @type {number}
   * @memberof ClientData
   */
  totalIncome?: number
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  flagChangeFio?: boolean
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  prevName?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  prevFamily?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  prevFatherName?: string
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  gatkaFlg?: boolean
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  issueCountryINN?: string
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  smsAgreeFlag?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  persInfoFlag?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  bkiFlag?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  taxUSAFlag?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  publicFlag?: boolean
  /**
   *
   * @type {number}
   * @memberof ClientData
   */
  dependents?: number
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  equalAddressFlag?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  isSalaryClient?: boolean
  /**
   *
   * @type {RefData}
   * @memberof ClientData
   */
  issueCountry?: RefData
  /**
   *
   * @type {RefData}
   * @memberof ClientData
   */
  martialStatus?: RefData
  /**
   *
   * @type {RefData}
   * @memberof ClientData
   */
  educationLevel?: RefData
  /**
   *
   * @type {RefData}
   * @memberof ClientData
   */
  ipdlStatusCode?: RefData
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  changeInfo?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  bornUsa?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  citizenUsaAndRf?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  greenCard?: boolean
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  usaRegAddress?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  usaFactAddress?: string
  /**
   *
   * @type {string}
   * @memberof ClientData
   */
  usaPhoneNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof ClientData
   */
  shortForm?: boolean
}
/**
 *
 * @export
 * @interface ClientRejectReasonReq
 */
export interface ClientRejectReasonReq {
  /**
   *
   * @type {number}
   * @memberof ClientRejectReasonReq
   */
  requestId: number
  /**
   *
   * @type {string}
   * @memberof ClientRejectReasonReq
   */
  stage: string
  /**
   *
   * @type {string}
   * @memberof ClientRejectReasonReq
   */
  codeNameRejectReason: string
  /**
   *
   * @type {string}
   * @memberof ClientRejectReasonReq
   */
  otherTextRejectReason?: string
}
/**
 *
 * @export
 * @interface CreateIssueReq
 */
export interface CreateIssueReq {
  /**
   *
   * @type {string}
   * @memberof CreateIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof CreateIssueReq
   */
  rboId?: number
  /**
   *
   * @type {boolean}
   * @memberof CreateIssueReq
   */
  isFinal?: boolean
  /**
   *
   * @type {PassportData}
   * @memberof CreateIssueReq
   */
  passportData?: PassportData
  /**
   *
   * @type {ClientData}
   * @memberof CreateIssueReq
   */
  clientData?: ClientData
  /**
   *
   * @type {CreditData}
   * @memberof CreateIssueReq
   */
  creditData?: CreditData
  /**
   *
   * @type {EmploymentData}
   * @memberof CreateIssueReq
   */
  employmentData?: EmploymentData
}
/**
 *
 * @export
 * @interface CreateIssueRes
 */
export interface CreateIssueRes {
  /**
   *
   * @type {string}
   * @memberof CreateIssueRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface CreditData
 */
export interface CreditData {
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  issueId?: number
  /**
   *
   * @type {string}
   * @memberof CreditData
   */
  channel?: string
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  requestSumma?: number
  /**
   *
   * @type {string}
   * @memberof CreditData
   */
  codeWord?: string
  /**
   *
   * @type {string}
   * @memberof CreditData
   */
  creditOtherGoal?: string
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  creditPeriod?: number
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  interestRateValue?: number
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  monthlyPayment?: number
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  step?: number
  /**
   *
   * @type {string}
   * @memberof CreditData
   */
  offerId?: string
  /**
   *
   * @type {RefData}
   * @memberof CreditData
   */
  loanPurpose?: RefData
  /**
   *
   * @type {boolean}
   * @memberof CreditData
   */
  insurance?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreditData
   */
  options?: boolean
  /**
   *
   * @type {number}
   * @memberof CreditData
   */
  payDay?: number
}
/**
 *
 * @export
 * @interface CreditParam
 */
export interface CreditParam {
  /**
   *
   * @type {number}
   * @memberof CreditParam
   */
  min?: number
  /**
   *
   * @type {number}
   * @memberof CreditParam
   */
  max?: number
}
/**
 *
 * @export
 * @interface DeliveryStatusRes
 */
export interface DeliveryStatusRes {
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  productName?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  deliveryStatus?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  requestStageDesc?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  deliveryDate?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  deliveryInterval?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  courName?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  courPhone?: string
  /**
   *
   * @type {string}
   * @memberof DeliveryStatusRes
   */
  addr?: string
}
/**
 *
 * @export
 * @interface DiffParam
 */
export interface DiffParam {
  /**
   *
   * @type {number}
   * @memberof DiffParam
   */
  diffPayment?: number
  /**
   *
   * @type {number}
   * @memberof DiffParam
   */
  diffRate?: number
}
/**
 *
 * @export
 * @interface DiffParm
 */
export interface DiffParm {
  /**
   *
   * @type {number}
   * @memberof DiffParm
   */
  diffMinRate?: number
  /**
   *
   * @type {number}
   * @memberof DiffParm
   */
  diffMaxRate?: number
  /**
   *
   * @type {number}
   * @memberof DiffParm
   */
  diffPayment?: number
}
/**
 *
 * @export
 * @interface Doc
 */
export interface Doc {
  /**
   *
   * @type {number}
   * @memberof Doc
   */
  docId?: number
  /**
   *
   * @type {string}
   * @memberof Doc
   */
  docName?: string
  /**
   *
   * @type {string}
   * @memberof Doc
   */
  docTypeSysName?: string
  /**
   *
   * @type {string}
   * @memberof Doc
   */
  agreeNum?: string
  /**
   *
   * @type {number}
   * @memberof Doc
   */
  docSize?: number
  /**
   *
   * @type {string}
   * @memberof Doc
   */
  displayName?: string
}
/**
 *
 * @export
 * @interface EmploymentData
 */
export interface EmploymentData {
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  employmentFullName?: string
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  workStartDate?: string
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  employmentPhone?: string
  /**
   *
   * @type {Address}
   * @memberof EmploymentData
   */
  employmentAddress?: Address
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  position?: string
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  employmentInn?: string
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  workStartYear?: string
  /**
   *
   * @type {RefData}
   * @memberof EmploymentData
   */
  workActivity?: RefData
  /**
   *
   * @type {RefData}
   * @memberof EmploymentData
   */
  positionLevel?: RefData
  /**
   *
   * @type {string}
   * @memberof EmploymentData
   */
  okved?: string
  /**
   *
   * @type {SoleTrader}
   * @memberof EmploymentData
   */
  soleTrader?: SoleTrader
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  requestId?: string
  /**
   *
   * @type {Error}
   * @memberof ErrorResponse
   */
  error?: Error
}
/**
 *
 * @export
 * @interface FlashAmount
 */
export interface FlashAmount {
  /**
   *
   * @type {number}
   * @memberof FlashAmount
   */
  approveSum?: number
  /**
   *
   * @type {Array<FlashVariant>}
   * @memberof FlashAmount
   */
  variants?: Array<FlashVariant>
}
/**
 *
 * @export
 * @interface FlashVariant
 */
export interface FlashVariant {
  /**
   *
   * @type {number}
   * @memberof FlashVariant
   */
  approvePayment?: number
  /**
   *
   * @type {number}
   * @memberof FlashVariant
   */
  approveTerm?: number
  /**
   *
   * @type {number}
   * @memberof FlashVariant
   */
  approveRate?: number
  /**
   *
   * @type {boolean}
   * @memberof FlashVariant
   */
  selected?: boolean
  /**
   *
   * @type {number}
   * @memberof FlashVariant
   */
  serviceOverpayment?: number
}
/**
 *
 * @export
 * @interface GraceLoanBalance
 */
export interface GraceLoanBalance {
  /**
   *
   * @type {number}
   * @memberof GraceLoanBalance
   */
  balancePayment?: number
  /**
   *
   * @type {number}
   * @memberof GraceLoanBalance
   */
  balanceRate?: number
}
/**
 *
 * @export
 * @interface Insurance
 */
export interface Insurance {
  /**
   *
   * @type {number}
   * @memberof Insurance
   */
  requestId?: number
  /**
   *
   * @type {number}
   * @memberof Insurance
   */
  insuranceId?: number
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  decisionId?: string
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  insuranceProgrammCode?: string
  /**
   *
   * @type {number}
   * @memberof Insurance
   */
  premiumAmount?: number
  /**
   *
   * @type {string}
   * @memberof Insurance
   */
  insuranceType?: string
  /**
   *
   * @type {number}
   * @memberof Insurance
   */
  insurancePercent?: number
}
/**
 *
 * @export
 * @interface InterestRate
 */
export interface InterestRate {
  /**
   *
   * @type {number}
   * @memberof InterestRate
   */
  term?: number
  /**
   *
   * @type {number}
   * @memberof InterestRate
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof InterestRate
   */
  salaryRate?: number
  /**
   *
   * @type {string}
   * @memberof InterestRate
   */
  product?: string
}
/**
 *
 * @export
 * @interface Issue
 */
export interface Issue {
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  prodSybType?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  popUpTitle?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  popUpBody?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  popUpPicture?: string
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  issueId?: number
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  requestId?: number
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  statusSysName?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  status?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  statusDisplay?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  statusCode?: string
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  issueDate?: string
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  docId?: number
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  docName?: string
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  docTypeSysName?: string
  /**
   *
   * @type {boolean}
   * @memberof Issue
   */
  showPromo?: boolean
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  creditPeriod?: number
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  interestRate?: string
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  monthlyPayment?: number
  /**
   *
   * @type {number}
   * @memberof Issue
   */
  payDay?: number
  /**
   *
   * @type {string}
   * @memberof Issue
   */
  codeRTK?: string
  /**
   *
   * @type {boolean}
   * @memberof Issue
   */
  agreement?: boolean
  /**
   *
   * @type {IssueDiffParam}
   * @memberof Issue
   */
  diffParam?: IssueDiffParam
  /**
   *
   * @type {IssueGraceLoanBalance}
   * @memberof Issue
   */
  graceLoanBalance?: IssueGraceLoanBalance
  /**
   *
   * @type {boolean}
   * @memberof Issue
   */
  useCalc?: boolean
  /**
   *
   * @type {AdditionalButton}
   * @memberof Issue
   */
  additionalButton?: AdditionalButton
}
/**
 *
 * @export
 * @interface IssueDiffParam
 */
export interface IssueDiffParam {
  /**
   *
   * @type {number}
   * @memberof IssueDiffParam
   */
  diffPayment?: number
  /**
   *
   * @type {number}
   * @memberof IssueDiffParam
   */
  diffRate?: number
}
/**
 *
 * @export
 * @interface IssueGraceLoanBalance
 */
export interface IssueGraceLoanBalance {
  /**
   *
   * @type {number}
   * @memberof IssueGraceLoanBalance
   */
  balancePayment?: number
  /**
   *
   * @type {number}
   * @memberof IssueGraceLoanBalance
   */
  balanceRate?: number
}
/**
 *
 * @export
 * @interface LoanIssueDocListReq
 */
export interface LoanIssueDocListReq {
  /**
   *
   * @type {string}
   * @memberof LoanIssueDocListReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof LoanIssueDocListReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof LoanIssueDocListReq
   */
  agreeNum?: string
}
/**
 *
 * @export
 * @interface LoanIssueDocListRes
 */
export interface LoanIssueDocListRes {
  /**
   *
   * @type {string}
   * @memberof LoanIssueDocListRes
   */
  requestId?: string
  /**
   *
   * @type {Array<Doc>}
   * @memberof LoanIssueDocListRes
   */
  docList?: Array<Doc>
}
/**
 *
 * @export
 * @interface LoanIssueListReq
 */
export interface LoanIssueListReq {
  /**
   *
   * @type {string}
   * @memberof LoanIssueListReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof LoanIssueListReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof LoanIssueListReq
   */
  phoneNumber?: string
}
/**
 *
 * @export
 * @interface LoanIssueListRes
 */
export interface LoanIssueListRes {
  /**
   *
   * @type {string}
   * @memberof LoanIssueListRes
   */
  requestId?: string
  /**
   *
   * @type {Array<Issue>}
   * @memberof LoanIssueListRes
   */
  issueList?: Array<Issue>
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  externalCode?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  details?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorInfo
 */
export interface MtsBankErrorInfo {
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof MtsBankErrorInfo
   */
  uuid?: string
}
/**
 *
 * @export
 * @interface MtsBankErrorResponse
 */
export interface MtsBankErrorResponse {
  /**
   *
   * @type {MtsBankErrorInfo}
   * @memberof MtsBankErrorResponse
   */
  error?: MtsBankErrorInfo
}
/**
 *
 * @export
 * @interface Offer
 */
export interface Offer {
  /**
   *
   * @type {number}
   * @memberof Offer
   */
  requestId?: number
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  decisionId?: string
  /**
   *
   * @type {number}
   * @memberof Offer
   */
  creditSum?: number
  /**
   *
   * @type {number}
   * @memberof Offer
   */
  creditMinSum?: number
  /**
   *
   * @type {number}
   * @memberof Offer
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof Offer
   */
  creditPeriod?: number
  /**
   *
   * @type {number}
   * @memberof Offer
   */
  monthlyPayment?: number
  /**
   *
   * @type {string}
   * @memberof Offer
   */
  decisionMode?: string
  /**
   *
   * @type {Array<Insurance>}
   * @memberof Offer
   */
  insuranceList?: Array<Insurance>
}
/**
 *
 * @export
 * @interface OfferDataRes
 */
export interface OfferDataRes {
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  maxRate?: number
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  maxSum?: number
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  maxTerm?: number
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  minRate?: number
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  minSum?: number
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  minTerm?: number
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  middleName?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  surname?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  currency?: string
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  payment?: number
  /**
   *
   * @type {boolean}
   * @memberof OfferDataRes
   */
  shortForm?: boolean
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  prodSubType?: string
  /**
   *
   * @type {boolean}
   * @memberof OfferDataRes
   */
  isCustEmpl?: boolean
  /**
   *
   * @type {number}
   * @memberof OfferDataRes
   */
  maxPayment?: number
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  risksegment?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  agreementId?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  popUpTimePP?: string
  /**
   *
   * @type {boolean}
   * @memberof OfferDataRes
   */
  fa?: boolean
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  product?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  commStrategy?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  clientCategory?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield2?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield3?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield4?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield5?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield6?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield7?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield10?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield8?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield9?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  xfield1?: string
  /**
   *
   * @type {string}
   * @memberof OfferDataRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface OfferListRes
 */
export interface OfferListRes {
  /**
   *
   * @type {string}
   * @memberof OfferListRes
   */
  requestId?: string
  /**
   *
   * @type {Array<Offer>}
   * @memberof OfferListRes
   */
  offers?: Array<Offer>
}
/**
 *
 * @export
 * @interface OfferPaymentCalcReq
 */
export interface OfferPaymentCalcReq {
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcReq
   */
  srcPhone?: string
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcReq
   */
  grace?: boolean
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcReq
   */
  appVersion?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcReq
   */
  platform?: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcReq
   */
  repaymentSum?: number
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcReq
   */
  offerId: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcReq
   */
  sum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcReq
   */
  term?: number
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcReq
   */
  insurance?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcReq
   */
  options?: boolean
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcReq
   */
  change: string
  /**
   *
   * @type {Array<CalcBoxes>}
   * @memberof OfferPaymentCalcReq
   */
  boxes?: Array<CalcBoxes>
}
/**
 *
 * @export
 * @interface OfferPaymentCalcRes
 */
export interface OfferPaymentCalcRes {
  /**
   *
   * @type {DiffParm}
   * @memberof OfferPaymentCalcRes
   */
  diffParm?: DiffParm
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  minPayment?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  maxPayment?: number
  /**
   *
   * @type {GraceLoanBalance}
   * @memberof OfferPaymentCalcRes
   */
  graceLoanBalance?: GraceLoanBalance
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcRes
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  payment?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  sum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  term?: number
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcRes
   */
  sumHelper?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcRes
   */
  termHelper?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcRes
   */
  insuranceHelper?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcRes
   */
  optionsHelper?: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  minRate?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  maxRate?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  commonMaxSum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  commonMinSum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  commonMaxTerm?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  commonMinTerm?: number
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcRes
   */
  grace?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcRes
   */
  insurance?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcRes
   */
  options?: boolean
  /**
   *
   * @type {Array<Boxes>}
   * @memberof OfferPaymentCalcRes
   */
  boxes?: Array<Boxes>
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  subscriptionSavings?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcRes
   */
  comissionPayment?: number
}
/**
 *
 * @export
 * @interface OfferPaymentCalcResFlash
 */
export interface OfferPaymentCalcResFlash {
  /**
   *
   * @type {Array<FlashAmount>}
   * @memberof OfferPaymentCalcResFlash
   */
  amounts: Array<FlashAmount>
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcResFlash
   */
  grace?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcResFlash
   */
  insurance?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcResFlash
   */
  options?: boolean
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcResFlash
   */
  subscriptionSavings?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcResFlash
   */
  comissionPayment?: number
}
/**
 *
 * @export
 * @interface OfferPaymentCalcTopUpReq
 */
export interface OfferPaymentCalcTopUpReq {
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpReq
   */
  offerId: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpReq
   */
  sum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpReq
   */
  term?: number
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcTopUpReq
   */
  insurance?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcTopUpReq
   */
  options?: boolean
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpReq
   */
  change: string
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcTopUpReq
   */
  grace?: boolean
  /**
   *
   * @type {Array<CalcBoxes>}
   * @memberof OfferPaymentCalcTopUpReq
   */
  boxes?: Array<CalcBoxes>
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpReq
   */
  appVersion?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpReq
   */
  platform?: string
  /**
   *
   * @type {boolean}
   * @memberof OfferPaymentCalcTopUpReq
   */
  more?: boolean
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpReq
   */
  payment?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpReq
   */
  repaymentSum: number
}
/**
 *
 * @export
 * @interface OfferPaymentCalcTopUpRes
 */
export interface OfferPaymentCalcTopUpRes {
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  minSum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  maxSum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  minTerm?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  maxTerm?: number
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpRes
   */
  requestId?: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  payment?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  sum?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  term?: number
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpRes
   */
  sumHelper?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpRes
   */
  termHelper?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpRes
   */
  insuranceHelper?: string
  /**
   *
   * @type {string}
   * @memberof OfferPaymentCalcTopUpRes
   */
  optionsHelper?: string
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  rate?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  minRate?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  maxRate?: number
  /**
   *
   * @type {Array<Boxes>}
   * @memberof OfferPaymentCalcTopUpRes
   */
  boxes?: Array<Boxes>
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  subscriptionSavings?: number
  /**
   *
   * @type {number}
   * @memberof OfferPaymentCalcTopUpRes
   */
  comissionPayment?: number
}
/**
 *
 * @export
 * @interface OfferSelectReq
 */
export interface OfferSelectReq {
  /**
   *
   * @type {string}
   * @memberof OfferSelectReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof OfferSelectReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof OfferSelectReq
   */
  decisionId: string
  /**
   *
   * @type {number}
   * @memberof OfferSelectReq
   */
  payDay: number
  /**
   *
   * @type {number}
   * @memberof OfferSelectReq
   */
  amount: number
  /**
   *
   * @type {number}
   * @memberof OfferSelectReq
   */
  requestId: number
}
/**
 *
 * @export
 * @interface OfferSelectRes
 */
export interface OfferSelectRes {
  /**
   *
   * @type {string}
   * @memberof OfferSelectRes
   */
  requestId?: string
  /**
   *
   * @type {boolean}
   * @memberof OfferSelectRes
   */
  readyToSign?: boolean
}
/**
 *
 * @export
 * @interface OpenDocReq
 */
export interface OpenDocReq {
  /**
   *
   * @type {string}
   * @memberof OpenDocReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof OpenDocReq
   */
  rboId?: number
  /**
   *
   * @type {number}
   * @memberof OpenDocReq
   */
  docId?: number
  /**
   *
   * @type {string}
   * @memberof OpenDocReq
   */
  agreeNum?: string
  /**
   *
   * @type {string}
   * @memberof OpenDocReq
   */
  docTypeSysName?: string
}
/**
 *
 * @export
 * @interface OpenDocRes
 */
export interface OpenDocRes {
  /**
   *
   * @type {string}
   * @memberof OpenDocRes
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof OpenDocRes
   */
  docData?: string
}
/**
 *
 * @export
 * @interface OpenIssueReq
 */
export interface OpenIssueReq {
  /**
   *
   * @type {string}
   * @memberof OpenIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof OpenIssueReq
   */
  rboId?: number
  /**
   *
   * @type {number}
   * @memberof OpenIssueReq
   */
  issueId?: number
  /**
   *
   * @type {boolean}
   * @memberof OpenIssueReq
   */
  shortForm?: boolean
}
/**
 *
 * @export
 * @interface OpenIssueRes
 */
export interface OpenIssueRes {
  /**
   *
   * @type {string}
   * @memberof OpenIssueRes
   */
  requestId?: string
  /**
   *
   * @type {PassportData}
   * @memberof OpenIssueRes
   */
  passportData?: PassportData
  /**
   *
   * @type {ClientData}
   * @memberof OpenIssueRes
   */
  clientData?: ClientData
  /**
   *
   * @type {CreditData}
   * @memberof OpenIssueRes
   */
  creditData?: CreditData
  /**
   *
   * @type {EmploymentData}
   * @memberof OpenIssueRes
   */
  employmentData?: EmploymentData
  /**
   *
   * @type {ParamData}
   * @memberof OpenIssueRes
   */
  paramData?: ParamData
}
/**
 *
 * @export
 * @interface OpenTopUpIssueReq
 */
export interface OpenTopUpIssueReq {
  /**
   *
   * @type {number}
   * @memberof OpenTopUpIssueReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof OpenTopUpIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {string}
   * @memberof OpenTopUpIssueReq
   */
  offerId: string
}
/**
 *
 * @export
 * @interface OpenTopUpIssueRes
 */
export interface OpenTopUpIssueRes {
  /**
   *
   * @type {number}
   * @memberof OpenTopUpIssueRes
   */
  issueId?: number
  /**
   *
   * @type {number}
   * @memberof OpenTopUpIssueRes
   */
  repaymentSum?: number
  /**
   *
   * @type {boolean}
   * @memberof OpenTopUpIssueRes
   */
  shortForm?: boolean
  /**
   *
   * @type {string}
   * @memberof OpenTopUpIssueRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface ParamData
 */
export interface ParamData {
  /**
   *
   * @type {CreditParam}
   * @memberof ParamData
   */
  creditAmt?: CreditParam
  /**
   *
   * @type {CreditParam}
   * @memberof ParamData
   */
  creditSalaryAmt?: CreditParam
  /**
   *
   * @type {CreditParam}
   * @memberof ParamData
   */
  creditTerm?: CreditParam
  /**
   *
   * @type {Array<InterestRate>}
   * @memberof ParamData
   */
  interestRateList?: Array<InterestRate>
}
/**
 *
 * @export
 * @interface PassportData
 */
export interface PassportData {
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  issueDateV2?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  birthDateV2?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  clientDoc?: string
  /**
   *
   * @type {Address}
   * @memberof PassportData
   */
  regAddress?: Address
  /**
   *
   * @type {Address}
   * @memberof PassportData
   */
  factAddress?: Address
  /**
   *
   * @type {number}
   * @memberof PassportData
   */
  sex?: number
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  birthDate?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  birthPlace?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  serial?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  number?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  issueOrgCode?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  issueOrgName?: string
  /**
   *
   * @type {string}
   * @memberof PassportData
   */
  issueDate?: string
}
/**
 *
 * @export
 * @interface PreSignIssueDoc
 */
export interface PreSignIssueDoc {
  /**
   *
   * @type {number}
   * @memberof PreSignIssueDoc
   */
  docId?: number
  /**
   *
   * @type {string}
   * @memberof PreSignIssueDoc
   */
  docName?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueDoc
   */
  docInfo?: string
}
/**
 *
 * @export
 * @interface PreSignIssueReq
 */
export interface PreSignIssueReq {
  /**
   *
   * @type {string}
   * @memberof PreSignIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof PreSignIssueReq
   */
  rboId?: number
  /**
   *
   * @type {number}
   * @memberof PreSignIssueReq
   */
  requestId: number
  /**
   *
   * @type {boolean}
   * @memberof PreSignIssueReq
   */
  secondCall?: boolean
  /**
   *
   * @type {AdditionalButton}
   * @memberof PreSignIssueReq
   */
  additionalButton?: AdditionalButton
}
/**
 *
 * @export
 * @interface PreSignIssueRes
 */
export interface PreSignIssueRes {
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  requestId?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  loanLimit?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  loanTerm?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  interestRate?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  prodSybType?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  monthlyPayment?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  issueDate?: string
  /**
   *
   * @type {Array<PreSignIssueDoc>}
   * @memberof PreSignIssueRes
   */
  docList?: Array<PreSignIssueDoc>
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  refinAmount?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  payDay?: string
  /**
   *
   * @type {string}
   * @memberof PreSignIssueRes
   */
  rateHelperText?: string
}
/**
 *
 * @export
 * @interface RefData
 */
export interface RefData {
  /**
   *
   * @type {string}
   * @memberof RefData
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof RefData
   */
  name?: string
}
/**
 *
 * @export
 * @interface RejectReasonRes
 */
export interface RejectReasonRes {
  /**
   *
   * @type {Array<RejectionReason>}
   * @memberof RejectReasonRes
   */
  rejectionReasons?: Array<RejectionReason>
  /**
   *
   * @type {string}
   * @memberof RejectReasonRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface RejectionReason
 */
export interface RejectionReason {
  /**
   *
   * @type {string}
   * @memberof RejectionReason
   */
  reasonName?: string
  /**
   *
   * @type {number}
   * @memberof RejectionReason
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof RejectionReason
   */
  codeName?: string
  /**
   *
   * @type {string}
   * @memberof RejectionReason
   */
  explanation?: string
  /**
   *
   * @type {string}
   * @memberof RejectionReason
   */
  explainText?: string
  /**
   *
   * @type {Array<Button>}
   * @memberof RejectionReason
   */
  buttons?: Array<Button>
}
/**
 *
 * @export
 * @interface SendDocsReq
 */
export interface SendDocsReq {
  /**
   *
   * @type {string}
   * @memberof SendDocsReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof SendDocsReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof SendDocsReq
   */
  email: string
  /**
   *
   * @type {number}
   * @memberof SendDocsReq
   */
  requestId: number
}
/**
 *
 * @export
 * @interface SendDocsRes
 */
export interface SendDocsRes {
  /**
   *
   * @type {string}
   * @memberof SendDocsRes
   */
  requestId?: string
}
/**
 *
 * @export
 * @interface Sets
 */
export interface Sets {
  /**
   *
   * @type {number}
   * @memberof Sets
   */
  price?: number
  /**
   *
   * @type {string}
   * @memberof Sets
   */
  setSysName?: string
  /**
   *
   * @type {string}
   * @memberof Sets
   */
  boxInfo?: string
  /**
   *
   * @type {string}
   * @memberof Sets
   */
  url?: string
}
/**
 *
 * @export
 * @interface SignIssueReq
 */
export interface SignIssueReq {
  /**
   *
   * @type {string}
   * @memberof SignIssueReq
   */
  srcPhone?: string
  /**
   *
   * @type {number}
   * @memberof SignIssueReq
   */
  rboId?: number
  /**
   *
   * @type {string}
   * @memberof SignIssueReq
   */
  code?: string
  /**
   *
   * @type {number}
   * @memberof SignIssueReq
   */
  requestId: number
  /**
   *
   * @type {string}
   * @memberof SignIssueReq
   */
  email?: string
}
/**
 *
 * @export
 * @interface SignIssueRes
 */
export interface SignIssueRes {
  /**
   *
   * @type {string}
   * @memberof SignIssueRes
   */
  requestId?: string
  /**
   *
   * @type {boolean}
   * @memberof SignIssueRes
   */
  inDO?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SignIssueRes
   */
  result?: boolean
}
/**
 *
 * @export
 * @interface SoleTrader
 */
export interface SoleTrader {
  /**
   *
   * @type {number}
   * @memberof SoleTrader
   */
  inn?: number
}
/**
 *
 * @export
 * @interface StoriesRes
 */
export interface StoriesRes {
  /**
   *
   * @type {number}
   * @memberof StoriesRes
   */
  total?: number
  /**
   *
   * @type {Array<Story>}
   * @memberof StoriesRes
   */
  data?: Array<Story>
  /**
   *
   * @type {string}
   * @memberof StoriesRes
   */
  storage?: string
}
/**
 *
 * @export
 * @interface Story
 */
export interface Story {
  /**
   *
   * @type {number}
   * @memberof Story
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Story
   */
  type?: string
  /**
   *
   * @type {number}
   * @memberof Story
   */
  delay?: number
  /**
   *
   * @type {string}
   * @memberof Story
   */
  titleText?: string
  /**
   *
   * @type {string}
   * @memberof Story
   */
  bodyText?: string
  /**
   *
   * @type {string}
   * @memberof Story
   */
  urlPic?: string
}
/**
 *
 * @export
 * @interface Templates
 */
export interface Templates {
  /**
   *
   * @type {number}
   * @memberof Templates
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  code?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  commStrategy?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  textEnabled?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  textDisabled?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  hint?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  details?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  helper?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof Templates
   */
  platform?: string
}
/**
 *
 * @export
 * @interface TemplatesRes
 */
export interface TemplatesRes {
  /**
   *
   * @type {Array<Templates>}
   * @memberof TemplatesRes
   */
  templates?: Array<Templates>
  /**
   *
   * @type {string}
   * @memberof TemplatesRes
   */
  requestId?: string
}

/**
 * LoanIssueApi - axios parameter creator
 * @export
 */
export const LoanIssueApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращающий комплекты коробочных продуктов в зависимости от суммы кредита
     * @param {string} creditGoalSysName
     * @param {number} sum
     * @param {string} [commStrategy]
     * @param {boolean} [grace]
     * @param {boolean} [insurance]
     * @param {boolean} [optionsBoxes]
     * @param {boolean} [more]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    boxesList(
      creditGoalSysName: string,
      sum: number,
      commStrategy?: string,
      grace?: boolean,
      insurance?: boolean,
      optionsBoxes?: boolean,
      more?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'creditGoalSysName' is not null or undefined
      if (creditGoalSysName === null || creditGoalSysName === undefined) {
        throw new RequiredError(
          'creditGoalSysName',
          'Required parameter creditGoalSysName was null or undefined when calling boxesList.'
        )
      }
      // verify required parameter 'sum' is not null or undefined
      if (sum === null || sum === undefined) {
        throw new RequiredError('sum', 'Required parameter sum was null or undefined when calling boxesList.')
      }
      const localVarPath = `/dbo-loan-issue/v1/info/boxes`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (creditGoalSysName !== undefined) {
        localVarQueryParameter['creditGoalSysName'] = creditGoalSysName
      }

      if (sum !== undefined) {
        localVarQueryParameter['sum'] = sum
      }

      if (commStrategy !== undefined) {
        localVarQueryParameter['commStrategy'] = commStrategy
      }

      if (grace !== undefined) {
        localVarQueryParameter['grace'] = grace
      }

      if (insurance !== undefined) {
        localVarQueryParameter['insurance'] = insurance
      }

      if (optionsBoxes !== undefined) {
        localVarQueryParameter['optionsBoxes'] = optionsBoxes
      }

      if (more !== undefined) {
        localVarQueryParameter['more'] = more
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary отмена заявки на кредит
     * @param {'v1'} version
     * @param {CancelIssueReq} [cancelIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelIssue(version: 'v1', cancelIssueReq?: CancelIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling cancelIssue.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/signing/cancelIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof cancelIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(cancelIssueReq !== undefined ? cancelIssueReq : {})
        : cancelIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary проверка наличия предзаполненной заявки на кредит у клиента
     * @param {'v1'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {CheckIssueReq} [checkIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIssue(
      version: 'v1',
      rboId?: string,
      authorization?: string,
      checkIssueReq?: CheckIssueReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling checkIssue.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/create/checkIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboId !== undefined && rboId !== null) {
        localVarHeaderParameter['rbo_id'] = String(rboId)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof checkIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(checkIssueReq !== undefined ? checkIssueReq : {})
        : checkIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary оформление заявки на кредит
     * @param {'v1'} version
     * @param {CreateIssueReq} [createIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIssue(version: 'v1', createIssueReq?: CreateIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling createIssue.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/create/createIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createIssueReq !== undefined ? createIssueReq : {})
        : createIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Сохранение и отправка анкеты на обработку
     * @param {'v1' | 'v2'} version
     * @param {CreateIssueReq} [createIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createShortIssue(version: 'v1' | 'v2', createIssueReq?: CreateIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling createShortIssue.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/create/createShortIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof createIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(createIssueReq !== undefined ? createIssueReq : {})
        : createIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод, позволяющий запросить информацию о доставке кредита
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveryStatus(version: 'v1', options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getDeliveryStatus.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/info/deliveryStatus`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод, позволяющий получить сторисы
     * @param {string} type
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStories(type: string, id?: number, options: any = {}): RequestArgs {
      // verify required parameter 'type' is not null or undefined
      if (type === null || type === undefined) {
        throw new RequiredError('type', 'Required parameter type was null or undefined when calling getStories.')
      }
      const localVarPath = `/dbo-loan-issue/v1/info/stories`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      if (id !== undefined) {
        localVarQueryParameter['id'] = id
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод, позволяющий запросить шаблоны для отображения текстовок на фронте приложения
     * @param {'v1'} version
     * @param {string} commStrategy
     * @param {string} [platform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(version: 'v1', commStrategy: string, platform?: string, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      // verify required parameter 'commStrategy' is not null or undefined
      if (commStrategy === null || commStrategy === undefined) {
        throw new RequiredError(
          'commStrategy',
          'Required parameter commStrategy was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/dbo-loan-issue/v1/info/templates`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (commStrategy !== undefined) {
        localVarQueryParameter['commStrategy'] = commStrategy
      }

      if (platform !== undefined) {
        localVarQueryParameter['platform'] = platform
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary список документов
     * @param {'v1'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {LoanIssueDocListReq} [loanIssueDocListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueDocList(
      version: 'v1',
      rboId?: string,
      authorization?: string,
      loanIssueDocListReq?: LoanIssueDocListReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling loanIssueDocList.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/info/loanIssueDocList`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboId !== undefined && rboId !== null) {
        localVarHeaderParameter['rbo_id'] = String(rboId)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof loanIssueDocListReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(loanIssueDocListReq !== undefined ? loanIssueDocListReq : {})
        : loanIssueDocListReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary список заявок
     * @param {'v1' | 'v2' | 'v3'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {LoanIssueListReq} [loanIssueListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueList(
      version: 'v1' | 'v2' | 'v3',
      rboId?: string,
      authorization?: string,
      loanIssueListReq?: LoanIssueListReq,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling loanIssueList.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/info/loanIssueList`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (rboId !== undefined && rboId !== null) {
        localVarHeaderParameter['rbo_id'] = String(rboId)
      }

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof loanIssueListReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(loanIssueListReq !== undefined ? loanIssueListReq : {})
        : loanIssueListReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод, позволяющий получить данные для заполнение страницы персонального предложения.
     * @param {'v1'} version
     * @param {string} os
     * @param {string} appName
     * @param {string} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerData(version: 'v1', os: string, appName: string, offerId: string, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling offerData.')
      }
      // verify required parameter 'os' is not null or undefined
      if (os === null || os === undefined) {
        throw new RequiredError('os', 'Required parameter os was null or undefined when calling offerData.')
      }
      // verify required parameter 'appName' is not null or undefined
      if (appName === null || appName === undefined) {
        throw new RequiredError('appName', 'Required parameter appName was null or undefined when calling offerData.')
      }
      // verify required parameter 'offerId' is not null or undefined
      if (offerId === null || offerId === undefined) {
        throw new RequiredError('offerId', 'Required parameter offerId was null or undefined when calling offerData.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/offerData`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (os !== undefined) {
        localVarQueryParameter['os'] = os
      }

      if (appName !== undefined) {
        localVarQueryParameter['appName'] = appName
      }

      if (offerId !== undefined) {
        localVarQueryParameter['offerId'] = offerId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary список предложений
     * @param {'v1'} version
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerList(version: 'v1', requestId: number, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling offerList.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling offerList.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/selection/offerList`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод, позволяющий получить расчёт ежемесячного платежа по выбранным параметрам кредита. Дополнительно данный метод осуществляет контроль соответствия выбранных данных максимальному допустимому платежу.
     * @param {'v1' | 'v2'} version
     * @param {OfferPaymentCalcReq} offerPaymentCalcReq
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPaymentCalc(
      version: 'v1' | 'v2',
      offerPaymentCalcReq: OfferPaymentCalcReq,
      platform?: string,
      appVersion?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling offerPaymentCalc.'
        )
      }
      // verify required parameter 'offerPaymentCalcReq' is not null or undefined
      if (offerPaymentCalcReq === null || offerPaymentCalcReq === undefined) {
        throw new RequiredError(
          'offerPaymentCalcReq',
          'Required parameter offerPaymentCalcReq was null or undefined when calling offerPaymentCalc.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/offerPaymentCalc`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (platform !== undefined && platform !== null) {
        localVarHeaderParameter['Platform'] = String(platform)
      }

      if (appVersion !== undefined && appVersion !== null) {
        localVarHeaderParameter['App-version'] = String(appVersion)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof offerPaymentCalcReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(offerPaymentCalcReq !== undefined ? offerPaymentCalcReq : {})
        : offerPaymentCalcReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Получение предельно допустимых значений и корректировка текущих параметров кредита
     * @param {OfferPaymentCalcTopUpReq} offerPaymentCalcTopUpReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPaymentCalcTopUp(offerPaymentCalcTopUpReq: OfferPaymentCalcTopUpReq, options: any = {}): RequestArgs {
      // verify required parameter 'offerPaymentCalcTopUpReq' is not null or undefined
      if (offerPaymentCalcTopUpReq === null || offerPaymentCalcTopUpReq === undefined) {
        throw new RequiredError(
          'offerPaymentCalcTopUpReq',
          'Required parameter offerPaymentCalcTopUpReq was null or undefined when calling offerPaymentCalcTopUp.'
        )
      }
      const localVarPath = `/dbo-loan-issue/v1/offerPaymentCalcTopUp`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof offerPaymentCalcTopUpReq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(offerPaymentCalcTopUpReq !== undefined ? offerPaymentCalcTopUpReq : {})
        : offerPaymentCalcTopUpReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary выбор предложения
     * @param {'v1'} version
     * @param {OfferSelectReq} [offerSelectReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerSelect(version: 'v1', offerSelectReq?: OfferSelectReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling offerSelect.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/selection/offerSelect`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof offerSelectReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(offerSelectReq !== undefined ? offerSelectReq : {})
        : offerSelectReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary получение документа
     * @param {'v1'} version
     * @param {OpenDocReq} [openDocReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openDoc(version: 'v1', openDocReq?: OpenDocReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling openDoc.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/signing/openDoc`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof openDocReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(openDocReq !== undefined ? openDocReq : {})
        : openDocReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary открытие формы заявки на кредит
     * @param {'v1'} version
     * @param {string} [authorization]
     * @param {OpenIssueReq} [openIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openIssue(version: 'v1', authorization?: string, openIssueReq?: OpenIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling openIssue.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/create/openIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (authorization !== undefined && authorization !== null) {
        localVarHeaderParameter['authorization'] = String(authorization)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof openIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(openIssueReq !== undefined ? openIssueReq : {})
        : openIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary открытие формы заявки на кредит
     * @param {OpenIssueReq} [openIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openIssueV2(openIssueReq?: OpenIssueReq, options: any = {}): RequestArgs {
      const localVarPath = `/dbo-loan-issue/v2/create/openIssue`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof openIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(openIssueReq !== undefined ? openIssueReq : {})
        : openIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод проводит предварительные проверки возможности оформления заявки на topUp и создаёт скелетон заявки в внутренней БД
     * @param {OpenTopUpIssueReq} openTopUpIssueReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openTopUpIssue(openTopUpIssueReq: OpenTopUpIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'openTopUpIssueReq' is not null or undefined
      if (openTopUpIssueReq === null || openTopUpIssueReq === undefined) {
        throw new RequiredError(
          'openTopUpIssueReq',
          'Required parameter openTopUpIssueReq was null or undefined when calling openTopUpIssue.'
        )
      }
      const localVarPath = `/dbo-loan-issue/v1/create/openTopUpIssue`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof openTopUpIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(openTopUpIssueReq !== undefined ? openTopUpIssueReq : {})
        : openTopUpIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary получение окончательных условий и печатных форм по кредитному договору
     * @param {'v1' | 'v2'} version
     * @param {PreSignIssueReq} [preSignIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preSignIssue(version: 'v1' | 'v2', preSignIssueReq?: PreSignIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling preSignIssue.'
        )
      }
      const localVarPath = `/dbo-loan-issue/{version}/signing/preSignIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof preSignIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(preSignIssueReq !== undefined ? preSignIssueReq : {})
        : preSignIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary отправка документов на почту
     * @param {'v1'} version
     * @param {SendDocsReq} [sendDocsReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendDocs(version: 'v1', sendDocsReq?: SendDocsReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling sendDocs.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/signing/sendDocs`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof sendDocsReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(sendDocsReq !== undefined ? sendDocsReq : {})
        : sendDocsReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary подписание условий по договору
     * @param {'v1'} version
     * @param {SignIssueReq} [signIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signIssue(version: 'v1', signIssueReq?: SignIssueReq, options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError('version', 'Required parameter version was null or undefined when calling signIssue.')
      }
      const localVarPath = `/dbo-loan-issue/{version}/signing/signIssue`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof signIssueReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(signIssueReq !== undefined ? signIssueReq : {})
        : signIssueReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanIssueApi - functional programming interface
 * @export
 */
export const LoanIssueApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод возвращающий комплекты коробочных продуктов в зависимости от суммы кредита
     * @param {string} creditGoalSysName
     * @param {number} sum
     * @param {string} [commStrategy]
     * @param {boolean} [grace]
     * @param {boolean} [insurance]
     * @param {boolean} [optionsBoxes]
     * @param {boolean} [more]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    boxesList(
      creditGoalSysName: string,
      sum: number,
      commStrategy?: string,
      grace?: boolean,
      insurance?: boolean,
      optionsBoxes?: boolean,
      more?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxListRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).boxesList(
        creditGoalSysName,
        sum,
        commStrategy,
        grace,
        insurance,
        optionsBoxes,
        more,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary отмена заявки на кредит
     * @param {'v1'} version
     * @param {CancelIssueReq} [cancelIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelIssue(
      version: 'v1',
      cancelIssueReq?: CancelIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CancelIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).cancelIssue(
        version,
        cancelIssueReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary проверка наличия предзаполненной заявки на кредит у клиента
     * @param {'v1'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {CheckIssueReq} [checkIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIssue(
      version: 'v1',
      rboId?: string,
      authorization?: string,
      checkIssueReq?: CheckIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).checkIssue(
        version,
        rboId,
        authorization,
        checkIssueReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary оформление заявки на кредит
     * @param {'v1'} version
     * @param {CreateIssueReq} [createIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIssue(
      version: 'v1',
      createIssueReq?: CreateIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).createIssue(
        version,
        createIssueReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Сохранение и отправка анкеты на обработку
     * @param {'v1' | 'v2'} version
     * @param {CreateIssueReq} [createIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createShortIssue(
      version: 'v1' | 'v2',
      createIssueReq?: CreateIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).createShortIssue(
        version,
        createIssueReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод, позволяющий запросить информацию о доставке кредита
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveryStatus(
      version: 'v1',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryStatusRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).getDeliveryStatus(version, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод, позволяющий получить сторисы
     * @param {string} type
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStories(
      type: string,
      id?: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoriesRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).getStories(type, id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод, позволяющий запросить шаблоны для отображения текстовок на фронте приложения
     * @param {'v1'} version
     * @param {string} commStrategy
     * @param {string} [platform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(
      version: 'v1',
      commStrategy: string,
      platform?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).getTemplates(
        version,
        commStrategy,
        platform,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary список документов
     * @param {'v1'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {LoanIssueDocListReq} [loanIssueDocListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueDocList(
      version: 'v1',
      rboId?: string,
      authorization?: string,
      loanIssueDocListReq?: LoanIssueDocListReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanIssueDocListRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).loanIssueDocList(
        version,
        rboId,
        authorization,
        loanIssueDocListReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary список заявок
     * @param {'v1' | 'v2' | 'v3'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {LoanIssueListReq} [loanIssueListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueList(
      version: 'v1' | 'v2' | 'v3',
      rboId?: string,
      authorization?: string,
      loanIssueListReq?: LoanIssueListReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanIssueListRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).loanIssueList(
        version,
        rboId,
        authorization,
        loanIssueListReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод, позволяющий получить данные для заполнение страницы персонального предложения.
     * @param {'v1'} version
     * @param {string} os
     * @param {string} appName
     * @param {string} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerData(
      version: 'v1',
      os: string,
      appName: string,
      offerId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferDataRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).offerData(
        version,
        os,
        appName,
        offerId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary список предложений
     * @param {'v1'} version
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerList(
      version: 'v1',
      requestId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferListRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).offerList(version, requestId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод, позволяющий получить расчёт ежемесячного платежа по выбранным параметрам кредита. Дополнительно данный метод осуществляет контроль соответствия выбранных данных максимальному допустимому платежу.
     * @param {'v1' | 'v2'} version
     * @param {OfferPaymentCalcReq} offerPaymentCalcReq
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPaymentCalc(
      version: 'v1' | 'v2',
      offerPaymentCalcReq: OfferPaymentCalcReq,
      platform?: string,
      appVersion?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPaymentCalcRes | OfferPaymentCalcResFlash> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).offerPaymentCalc(
        version,
        offerPaymentCalcReq,
        platform,
        appVersion,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Получение предельно допустимых значений и корректировка текущих параметров кредита
     * @param {OfferPaymentCalcTopUpReq} offerPaymentCalcTopUpReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPaymentCalcTopUp(
      offerPaymentCalcTopUpReq: OfferPaymentCalcTopUpReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferPaymentCalcTopUpRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).offerPaymentCalcTopUp(
        offerPaymentCalcTopUpReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary выбор предложения
     * @param {'v1'} version
     * @param {OfferSelectReq} [offerSelectReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerSelect(
      version: 'v1',
      offerSelectReq?: OfferSelectReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferSelectRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).offerSelect(
        version,
        offerSelectReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary получение документа
     * @param {'v1'} version
     * @param {OpenDocReq} [openDocReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openDoc(
      version: 'v1',
      openDocReq?: OpenDocReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenDocRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).openDoc(version, openDocReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary открытие формы заявки на кредит
     * @param {'v1'} version
     * @param {string} [authorization]
     * @param {OpenIssueReq} [openIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openIssue(
      version: 'v1',
      authorization?: string,
      openIssueReq?: OpenIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).openIssue(
        version,
        authorization,
        openIssueReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary открытие формы заявки на кредит
     * @param {OpenIssueReq} [openIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openIssueV2(
      openIssueReq?: OpenIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).openIssueV2(openIssueReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод проводит предварительные проверки возможности оформления заявки на topUp и создаёт скелетон заявки в внутренней БД
     * @param {OpenTopUpIssueReq} openTopUpIssueReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openTopUpIssue(
      openTopUpIssueReq: OpenTopUpIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenTopUpIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).openTopUpIssue(openTopUpIssueReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary получение окончательных условий и печатных форм по кредитному договору
     * @param {'v1' | 'v2'} version
     * @param {PreSignIssueReq} [preSignIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preSignIssue(
      version: 'v1' | 'v2',
      preSignIssueReq?: PreSignIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreSignIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).preSignIssue(
        version,
        preSignIssueReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary отправка документов на почту
     * @param {'v1'} version
     * @param {SendDocsReq} [sendDocsReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendDocs(
      version: 'v1',
      sendDocsReq?: SendDocsReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendDocsRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).sendDocs(version, sendDocsReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary подписание условий по договору
     * @param {'v1'} version
     * @param {SignIssueReq} [signIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signIssue(
      version: 'v1',
      signIssueReq?: SignIssueReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignIssueRes> {
      const localVarAxiosArgs = LoanIssueApiAxiosParamCreator(configuration).signIssue(version, signIssueReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanIssueApi - factory interface
 * @export
 */
export const LoanIssueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Метод возвращающий комплекты коробочных продуктов в зависимости от суммы кредита
     * @param {string} creditGoalSysName
     * @param {number} sum
     * @param {string} [commStrategy]
     * @param {boolean} [grace]
     * @param {boolean} [insurance]
     * @param {boolean} [optionsBoxes]
     * @param {boolean} [more]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    boxesList(
      creditGoalSysName: string,
      sum: number,
      commStrategy?: string,
      grace?: boolean,
      insurance?: boolean,
      optionsBoxes?: boolean,
      more?: boolean,
      options?: any
    ): AxiosPromise<BoxListRes> {
      return LoanIssueApiFp(configuration).boxesList(
        creditGoalSysName,
        sum,
        commStrategy,
        grace,
        insurance,
        optionsBoxes,
        more,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary отмена заявки на кредит
     * @param {'v1'} version
     * @param {CancelIssueReq} [cancelIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelIssue(version: 'v1', cancelIssueReq?: CancelIssueReq, options?: any): AxiosPromise<CancelIssueRes> {
      return LoanIssueApiFp(configuration).cancelIssue(version, cancelIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary проверка наличия предзаполненной заявки на кредит у клиента
     * @param {'v1'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {CheckIssueReq} [checkIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkIssue(
      version: 'v1',
      rboId?: string,
      authorization?: string,
      checkIssueReq?: CheckIssueReq,
      options?: any
    ): AxiosPromise<CheckIssueRes> {
      return LoanIssueApiFp(configuration).checkIssue(
        version,
        rboId,
        authorization,
        checkIssueReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary оформление заявки на кредит
     * @param {'v1'} version
     * @param {CreateIssueReq} [createIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createIssue(version: 'v1', createIssueReq?: CreateIssueReq, options?: any): AxiosPromise<CreateIssueRes> {
      return LoanIssueApiFp(configuration).createIssue(version, createIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Сохранение и отправка анкеты на обработку
     * @param {'v1' | 'v2'} version
     * @param {CreateIssueReq} [createIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createShortIssue(
      version: 'v1' | 'v2',
      createIssueReq?: CreateIssueReq,
      options?: any
    ): AxiosPromise<CreateIssueRes> {
      return LoanIssueApiFp(configuration).createShortIssue(version, createIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод, позволяющий запросить информацию о доставке кредита
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDeliveryStatus(version: 'v1', options?: any): AxiosPromise<DeliveryStatusRes> {
      return LoanIssueApiFp(configuration).getDeliveryStatus(version, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод, позволяющий получить сторисы
     * @param {string} type
     * @param {number} [id]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStories(type: string, id?: number, options?: any): AxiosPromise<StoriesRes> {
      return LoanIssueApiFp(configuration).getStories(type, id, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод, позволяющий запросить шаблоны для отображения текстовок на фронте приложения
     * @param {'v1'} version
     * @param {string} commStrategy
     * @param {string} [platform]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(version: 'v1', commStrategy: string, platform?: string, options?: any): AxiosPromise<TemplatesRes> {
      return LoanIssueApiFp(configuration).getTemplates(version, commStrategy, platform, options)(axios, basePath)
    },
    /**
     *
     * @summary список документов
     * @param {'v1'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {LoanIssueDocListReq} [loanIssueDocListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueDocList(
      version: 'v1',
      rboId?: string,
      authorization?: string,
      loanIssueDocListReq?: LoanIssueDocListReq,
      options?: any
    ): AxiosPromise<LoanIssueDocListRes> {
      return LoanIssueApiFp(configuration).loanIssueDocList(
        version,
        rboId,
        authorization,
        loanIssueDocListReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary список заявок
     * @param {'v1' | 'v2' | 'v3'} version
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {LoanIssueListReq} [loanIssueListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueList(
      version: 'v1' | 'v2' | 'v3',
      rboId?: string,
      authorization?: string,
      loanIssueListReq?: LoanIssueListReq,
      options?: any
    ): AxiosPromise<LoanIssueListRes> {
      return LoanIssueApiFp(configuration).loanIssueList(
        version,
        rboId,
        authorization,
        loanIssueListReq,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Метод, позволяющий получить данные для заполнение страницы персонального предложения.
     * @param {'v1'} version
     * @param {string} os
     * @param {string} appName
     * @param {string} offerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerData(version: 'v1', os: string, appName: string, offerId: string, options?: any): AxiosPromise<OfferDataRes> {
      return LoanIssueApiFp(configuration).offerData(version, os, appName, offerId, options)(axios, basePath)
    },
    /**
     *
     * @summary список предложений
     * @param {'v1'} version
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerList(version: 'v1', requestId: number, options?: any): AxiosPromise<OfferListRes> {
      return LoanIssueApiFp(configuration).offerList(version, requestId, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод, позволяющий получить расчёт ежемесячного платежа по выбранным параметрам кредита. Дополнительно данный метод осуществляет контроль соответствия выбранных данных максимальному допустимому платежу.
     * @param {'v1' | 'v2'} version
     * @param {OfferPaymentCalcReq} offerPaymentCalcReq
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPaymentCalc(
      version: 'v1' | 'v2',
      offerPaymentCalcReq: OfferPaymentCalcReq,
      platform?: string,
      appVersion?: string,
      options?: any
    ): AxiosPromise<OfferPaymentCalcRes | OfferPaymentCalcResFlash> {
      return LoanIssueApiFp(configuration).offerPaymentCalc(
        version,
        offerPaymentCalcReq,
        platform,
        appVersion,
        options
      )(axios, basePath)
    },
    /**
     *
     * @summary Получение предельно допустимых значений и корректировка текущих параметров кредита
     * @param {OfferPaymentCalcTopUpReq} offerPaymentCalcTopUpReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerPaymentCalcTopUp(
      offerPaymentCalcTopUpReq: OfferPaymentCalcTopUpReq,
      options?: any
    ): AxiosPromise<OfferPaymentCalcTopUpRes> {
      return LoanIssueApiFp(configuration).offerPaymentCalcTopUp(offerPaymentCalcTopUpReq, options)(axios, basePath)
    },
    /**
     *
     * @summary выбор предложения
     * @param {'v1'} version
     * @param {OfferSelectReq} [offerSelectReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    offerSelect(version: 'v1', offerSelectReq?: OfferSelectReq, options?: any): AxiosPromise<OfferSelectRes> {
      return LoanIssueApiFp(configuration).offerSelect(version, offerSelectReq, options)(axios, basePath)
    },
    /**
     *
     * @summary получение документа
     * @param {'v1'} version
     * @param {OpenDocReq} [openDocReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openDoc(version: 'v1', openDocReq?: OpenDocReq, options?: any): AxiosPromise<OpenDocRes> {
      return LoanIssueApiFp(configuration).openDoc(version, openDocReq, options)(axios, basePath)
    },
    /**
     *
     * @summary открытие формы заявки на кредит
     * @param {'v1'} version
     * @param {string} [authorization]
     * @param {OpenIssueReq} [openIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openIssue(
      version: 'v1',
      authorization?: string,
      openIssueReq?: OpenIssueReq,
      options?: any
    ): AxiosPromise<OpenIssueRes> {
      return LoanIssueApiFp(configuration).openIssue(version, authorization, openIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary открытие формы заявки на кредит
     * @param {OpenIssueReq} [openIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openIssueV2(openIssueReq?: OpenIssueReq, options?: any): AxiosPromise<OpenIssueRes> {
      return LoanIssueApiFp(configuration).openIssueV2(openIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary Метод проводит предварительные проверки возможности оформления заявки на topUp и создаёт скелетон заявки в внутренней БД
     * @param {OpenTopUpIssueReq} openTopUpIssueReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    openTopUpIssue(openTopUpIssueReq: OpenTopUpIssueReq, options?: any): AxiosPromise<OpenTopUpIssueRes> {
      return LoanIssueApiFp(configuration).openTopUpIssue(openTopUpIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary получение окончательных условий и печатных форм по кредитному договору
     * @param {'v1' | 'v2'} version
     * @param {PreSignIssueReq} [preSignIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    preSignIssue(
      version: 'v1' | 'v2',
      preSignIssueReq?: PreSignIssueReq,
      options?: any
    ): AxiosPromise<PreSignIssueRes> {
      return LoanIssueApiFp(configuration).preSignIssue(version, preSignIssueReq, options)(axios, basePath)
    },
    /**
     *
     * @summary отправка документов на почту
     * @param {'v1'} version
     * @param {SendDocsReq} [sendDocsReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendDocs(version: 'v1', sendDocsReq?: SendDocsReq, options?: any): AxiosPromise<SendDocsRes> {
      return LoanIssueApiFp(configuration).sendDocs(version, sendDocsReq, options)(axios, basePath)
    },
    /**
     *
     * @summary подписание условий по договору
     * @param {'v1'} version
     * @param {SignIssueReq} [signIssueReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signIssue(version: 'v1', signIssueReq?: SignIssueReq, options?: any): AxiosPromise<SignIssueRes> {
      return LoanIssueApiFp(configuration).signIssue(version, signIssueReq, options)(axios, basePath)
    },
  }
}

/**
 * LoanIssueApi - object-oriented interface
 * @export
 * @class LoanIssueApi
 * @extends {BaseAPI}
 */
export class LoanIssueApi extends BaseAPI {
  /**
   *
   * @summary Метод возвращающий комплекты коробочных продуктов в зависимости от суммы кредита
   * @param {string} creditGoalSysName
   * @param {number} sum
   * @param {string} [commStrategy]
   * @param {boolean} [grace]
   * @param {boolean} [insurance]
   * @param {boolean} [optionsBoxes]
   * @param {boolean} [more]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public boxesList(
    creditGoalSysName: string,
    sum: number,
    commStrategy?: string,
    grace?: boolean,
    insurance?: boolean,
    optionsBoxes?: boolean,
    more?: boolean,
    options?: any
  ) {
    return LoanIssueApiFp(this.configuration).boxesList(
      creditGoalSysName,
      sum,
      commStrategy,
      grace,
      insurance,
      optionsBoxes,
      more,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary отмена заявки на кредит
   * @param {'v1'} version
   * @param {CancelIssueReq} [cancelIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public cancelIssue(version: 'v1', cancelIssueReq?: CancelIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).cancelIssue(version, cancelIssueReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary проверка наличия предзаполненной заявки на кредит у клиента
   * @param {'v1'} version
   * @param {string} [rboId]
   * @param {string} [authorization]
   * @param {CheckIssueReq} [checkIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public checkIssue(
    version: 'v1',
    rboId?: string,
    authorization?: string,
    checkIssueReq?: CheckIssueReq,
    options?: any
  ) {
    return LoanIssueApiFp(this.configuration).checkIssue(
      version,
      rboId,
      authorization,
      checkIssueReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary оформление заявки на кредит
   * @param {'v1'} version
   * @param {CreateIssueReq} [createIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public createIssue(version: 'v1', createIssueReq?: CreateIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).createIssue(version, createIssueReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Сохранение и отправка анкеты на обработку
   * @param {'v1' | 'v2'} version
   * @param {CreateIssueReq} [createIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public createShortIssue(version: 'v1' | 'v2', createIssueReq?: CreateIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).createShortIssue(
      version,
      createIssueReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод, позволяющий запросить информацию о доставке кредита
   * @param {'v1'} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public getDeliveryStatus(version: 'v1', options?: any) {
    return LoanIssueApiFp(this.configuration).getDeliveryStatus(version, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод, позволяющий получить сторисы
   * @param {string} type
   * @param {number} [id]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public getStories(type: string, id?: number, options?: any) {
    return LoanIssueApiFp(this.configuration).getStories(type, id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод, позволяющий запросить шаблоны для отображения текстовок на фронте приложения
   * @param {'v1'} version
   * @param {string} commStrategy
   * @param {string} [platform]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public getTemplates(version: 'v1', commStrategy: string, platform?: string, options?: any) {
    return LoanIssueApiFp(this.configuration).getTemplates(
      version,
      commStrategy,
      platform,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary список документов
   * @param {'v1'} version
   * @param {string} [rboId]
   * @param {string} [authorization]
   * @param {LoanIssueDocListReq} [loanIssueDocListReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public loanIssueDocList(
    version: 'v1',
    rboId?: string,
    authorization?: string,
    loanIssueDocListReq?: LoanIssueDocListReq,
    options?: any
  ) {
    return LoanIssueApiFp(this.configuration).loanIssueDocList(
      version,
      rboId,
      authorization,
      loanIssueDocListReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary список заявок
   * @param {'v1' | 'v2' | 'v3'} version
   * @param {string} [rboId]
   * @param {string} [authorization]
   * @param {LoanIssueListReq} [loanIssueListReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public loanIssueList(
    version: 'v1' | 'v2' | 'v3',
    rboId?: string,
    authorization?: string,
    loanIssueListReq?: LoanIssueListReq,
    options?: any
  ) {
    return LoanIssueApiFp(this.configuration).loanIssueList(
      version,
      rboId,
      authorization,
      loanIssueListReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод, позволяющий получить данные для заполнение страницы персонального предложения.
   * @param {'v1'} version
   * @param {string} os
   * @param {string} appName
   * @param {string} offerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public offerData(version: 'v1', os: string, appName: string, offerId: string, options?: any) {
    return LoanIssueApiFp(this.configuration).offerData(
      version,
      os,
      appName,
      offerId,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary список предложений
   * @param {'v1'} version
   * @param {number} requestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public offerList(version: 'v1', requestId: number, options?: any) {
    return LoanIssueApiFp(this.configuration).offerList(version, requestId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод, позволяющий получить расчёт ежемесячного платежа по выбранным параметрам кредита. Дополнительно данный метод осуществляет контроль соответствия выбранных данных максимальному допустимому платежу.
   * @param {'v1' | 'v2'} version
   * @param {OfferPaymentCalcReq} offerPaymentCalcReq
   * @param {string} [platform]
   * @param {string} [appVersion]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public offerPaymentCalc(
    version: 'v1' | 'v2',
    offerPaymentCalcReq: OfferPaymentCalcReq,
    platform?: string,
    appVersion?: string,
    options?: any
  ) {
    return LoanIssueApiFp(this.configuration).offerPaymentCalc(
      version,
      offerPaymentCalcReq,
      platform,
      appVersion,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение предельно допустимых значений и корректировка текущих параметров кредита
   * @param {OfferPaymentCalcTopUpReq} offerPaymentCalcTopUpReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public offerPaymentCalcTopUp(offerPaymentCalcTopUpReq: OfferPaymentCalcTopUpReq, options?: any) {
    return LoanIssueApiFp(this.configuration).offerPaymentCalcTopUp(offerPaymentCalcTopUpReq, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary выбор предложения
   * @param {'v1'} version
   * @param {OfferSelectReq} [offerSelectReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public offerSelect(version: 'v1', offerSelectReq?: OfferSelectReq, options?: any) {
    return LoanIssueApiFp(this.configuration).offerSelect(version, offerSelectReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary получение документа
   * @param {'v1'} version
   * @param {OpenDocReq} [openDocReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public openDoc(version: 'v1', openDocReq?: OpenDocReq, options?: any) {
    return LoanIssueApiFp(this.configuration).openDoc(version, openDocReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary открытие формы заявки на кредит
   * @param {'v1'} version
   * @param {string} [authorization]
   * @param {OpenIssueReq} [openIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public openIssue(version: 'v1', authorization?: string, openIssueReq?: OpenIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).openIssue(
      version,
      authorization,
      openIssueReq,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @summary открытие формы заявки на кредит
   * @param {OpenIssueReq} [openIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public openIssueV2(openIssueReq?: OpenIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).openIssueV2(openIssueReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Метод проводит предварительные проверки возможности оформления заявки на topUp и создаёт скелетон заявки в внутренней БД
   * @param {OpenTopUpIssueReq} openTopUpIssueReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public openTopUpIssue(openTopUpIssueReq: OpenTopUpIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).openTopUpIssue(openTopUpIssueReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary получение окончательных условий и печатных форм по кредитному договору
   * @param {'v1' | 'v2'} version
   * @param {PreSignIssueReq} [preSignIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public preSignIssue(version: 'v1' | 'v2', preSignIssueReq?: PreSignIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).preSignIssue(version, preSignIssueReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary отправка документов на почту
   * @param {'v1'} version
   * @param {SendDocsReq} [sendDocsReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public sendDocs(version: 'v1', sendDocsReq?: SendDocsReq, options?: any) {
    return LoanIssueApiFp(this.configuration).sendDocs(version, sendDocsReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary подписание условий по договору
   * @param {'v1'} version
   * @param {SignIssueReq} [signIssueReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueApi
   */
  public signIssue(version: 'v1', signIssueReq?: SignIssueReq, options?: any) {
    return LoanIssueApiFp(this.configuration).signIssue(version, signIssueReq, options)(this.axios, this.basePath)
  }
}

/**
 * LoanIssueControllerV1Api - axios parameter creator
 * @export
 */
export const LoanIssueControllerV1ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод передачи выбранной Клиентом причины отказа от кредитования.
     * @param {ClientRejectReasonReq} clientRejectReasonReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientRejectReason(clientRejectReasonReq: ClientRejectReasonReq, options: any = {}): RequestArgs {
      // verify required parameter 'clientRejectReasonReq' is not null or undefined
      if (clientRejectReasonReq === null || clientRejectReasonReq === undefined) {
        throw new RequiredError(
          'clientRejectReasonReq',
          'Required parameter clientRejectReasonReq was null or undefined when calling clientRejectReason.'
        )
      }
      const localVarPath = `/dbo-loan-issue/v1/clientRejectReason`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof clientRejectReasonReq !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(clientRejectReasonReq !== undefined ? clientRejectReasonReq : {})
        : clientRejectReasonReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Метод возвращает список причин отказа от кредита на этапе калькулятора, выдачи кредита и подписании документов.
     * @param {string} stage
     * @param {string} [productRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReason(stage: string, productRequest?: string, options: any = {}): RequestArgs {
      // verify required parameter 'stage' is not null or undefined
      if (stage === null || stage === undefined) {
        throw new RequiredError('stage', 'Required parameter stage was null or undefined when calling rejectReason.')
      }
      const localVarPath = `/dbo-loan-issue/v1/rejectReason`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (stage !== undefined) {
        localVarQueryParameter['stage'] = stage
      }

      if (productRequest !== undefined) {
        localVarQueryParameter['productRequest'] = productRequest
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanIssueControllerV1Api - functional programming interface
 * @export
 */
export const LoanIssueControllerV1ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Метод передачи выбранной Клиентом причины отказа от кредитования.
     * @param {ClientRejectReasonReq} clientRejectReasonReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientRejectReason(
      clientRejectReasonReq: ClientRejectReasonReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = LoanIssueControllerV1ApiAxiosParamCreator(configuration).clientRejectReason(
        clientRejectReasonReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Метод возвращает список причин отказа от кредита на этапе калькулятора, выдачи кредита и подписании документов.
     * @param {string} stage
     * @param {string} [productRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReason(
      stage: string,
      productRequest?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RejectReasonRes> {
      const localVarAxiosArgs = LoanIssueControllerV1ApiAxiosParamCreator(configuration).rejectReason(
        stage,
        productRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanIssueControllerV1Api - factory interface
 * @export
 */
export const LoanIssueControllerV1ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Метод передачи выбранной Клиентом причины отказа от кредитования.
     * @param {ClientRejectReasonReq} clientRejectReasonReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientRejectReason(clientRejectReasonReq: ClientRejectReasonReq, options?: any): AxiosPromise<void> {
      return LoanIssueControllerV1ApiFp(configuration).clientRejectReason(clientRejectReasonReq, options)(
        axios,
        basePath
      )
    },
    /**
     *
     * @summary Метод возвращает список причин отказа от кредита на этапе калькулятора, выдачи кредита и подписании документов.
     * @param {string} stage
     * @param {string} [productRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectReason(stage: string, productRequest?: string, options?: any): AxiosPromise<RejectReasonRes> {
      return LoanIssueControllerV1ApiFp(configuration).rejectReason(stage, productRequest, options)(axios, basePath)
    },
  }
}

/**
 * LoanIssueControllerV1Api - object-oriented interface
 * @export
 * @class LoanIssueControllerV1Api
 * @extends {BaseAPI}
 */
export class LoanIssueControllerV1Api extends BaseAPI {
  /**
   *
   * @summary Метод передачи выбранной Клиентом причины отказа от кредитования.
   * @param {ClientRejectReasonReq} clientRejectReasonReq
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueControllerV1Api
   */
  public clientRejectReason(clientRejectReasonReq: ClientRejectReasonReq, options?: any) {
    return LoanIssueControllerV1ApiFp(this.configuration).clientRejectReason(clientRejectReasonReq, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Метод возвращает список причин отказа от кредита на этапе калькулятора, выдачи кредита и подписании документов.
   * @param {string} stage
   * @param {string} [productRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueControllerV1Api
   */
  public rejectReason(stage: string, productRequest?: string, options?: any) {
    return LoanIssueControllerV1ApiFp(this.configuration).rejectReason(
      stage,
      productRequest,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * LoanIssueControllerV2Api - axios parameter creator
 * @export
 */
export const LoanIssueControllerV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary список документов
     * @param {LoanIssueDocListReq} [loanIssueDocListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueDocListV2(loanIssueDocListReq?: LoanIssueDocListReq, options: any = {}): RequestArgs {
      const localVarPath = `/dbo-loan-issue/v2/info/loanIssueDocList`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof loanIssueDocListReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(loanIssueDocListReq !== undefined ? loanIssueDocListReq : {})
        : loanIssueDocListReq || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LoanIssueControllerV2Api - functional programming interface
 * @export
 */
export const LoanIssueControllerV2ApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary список документов
     * @param {LoanIssueDocListReq} [loanIssueDocListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueDocListV2(
      loanIssueDocListReq?: LoanIssueDocListReq,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanIssueDocListRes> {
      const localVarAxiosArgs = LoanIssueControllerV2ApiAxiosParamCreator(configuration).loanIssueDocListV2(
        loanIssueDocListReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * LoanIssueControllerV2Api - factory interface
 * @export
 */
export const LoanIssueControllerV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary список документов
     * @param {LoanIssueDocListReq} [loanIssueDocListReq]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    loanIssueDocListV2(loanIssueDocListReq?: LoanIssueDocListReq, options?: any): AxiosPromise<LoanIssueDocListRes> {
      return LoanIssueControllerV2ApiFp(configuration).loanIssueDocListV2(loanIssueDocListReq, options)(axios, basePath)
    },
  }
}

/**
 * LoanIssueControllerV2Api - object-oriented interface
 * @export
 * @class LoanIssueControllerV2Api
 * @extends {BaseAPI}
 */
export class LoanIssueControllerV2Api extends BaseAPI {
  /**
   *
   * @summary список документов
   * @param {LoanIssueDocListReq} [loanIssueDocListReq]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LoanIssueControllerV2Api
   */
  public loanIssueDocListV2(loanIssueDocListReq?: LoanIssueDocListReq, options?: any) {
    return LoanIssueControllerV2ApiFp(this.configuration).loanIssueDocListV2(loanIssueDocListReq, options)(
      this.axios,
      this.basePath
    )
  }
}
