// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AvailableConditionsResponse
 */
export interface AvailableConditionsResponse {
  /**
   *
   * @type {Array<ConditionModel>}
   * @memberof AvailableConditionsResponse
   */
  conditions?: Array<ConditionModel>
}
/**
 *
 * @export
 * @interface ConditionModel
 */
export interface ConditionModel {
  /**
   *
   * @type {string}
   * @memberof ConditionModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ConditionModel
   */
  description?: string
  /**
   *
   * @type {Array<ConditionParam>}
   * @memberof ConditionModel
   */
  params?: Array<ConditionParam>
}
/**
 *
 * @export
 * @interface ConditionParam
 */
export interface ConditionParam {
  /**
   *
   * @type {string}
   * @memberof ConditionParam
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ConditionParam
   */
  type?: string
}
/**
 *
 * @export
 * @interface Conditions
 */
export interface Conditions {
  /**
   *
   * @type {string}
   * @memberof Conditions
   */
  conditionsName?: string
  /**
   *
   * @type {Array<ConditionsParams>}
   * @memberof Conditions
   */
  params?: Array<ConditionsParams>
}
/**
 *
 * @export
 * @interface ConditionsParams
 */
export interface ConditionsParams {
  /**
   *
   * @type {string}
   * @memberof ConditionsParams
   */
  paramName?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ConditionsParams
   */
  paramValue?: Array<string>
  /**
   *
   * @type {string}
   * @memberof ConditionsParams
   */
  type?: string
}
/**
 *
 * @export
 * @interface DeleteResponse
 */
export interface DeleteResponse {
  /**
   *
   * @type {number}
   * @memberof DeleteResponse
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof DeleteResponse
   */
  message?: string
}
/**
 *
 * @export
 * @interface Health
 */
export interface Health {
  /**
   *
   * @type {string}
   * @memberof Health
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof Health
   */
  status?: string
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof Health
   */
  details?: { [key: string]: object }
}
/**
 *
 * @export
 * @interface OnboardingForCms
 */
export interface OnboardingForCms {
  /**
   *
   * @type {number}
   * @memberof OnboardingForCms
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OnboardingForCms
   */
  alias?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingForCms
   */
  fromAppVersion?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingForCms
   */
  toAppVersion?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingForCms
   */
  place?: string
  /**
   *
   * @type {Array<Platform>}
   * @memberof OnboardingForCms
   */
  platforms?: Array<Platform>
  /**
   *
   * @type {boolean}
   * @memberof OnboardingForCms
   */
  onlyForDeeplink?: boolean
  /**
   *
   * @type {number}
   * @memberof OnboardingForCms
   */
  triggerId?: number
  /**
   *
   * @type {string}
   * @memberof OnboardingForCms
   */
  triggerName?: string
  /**
   *
   * @type {boolean}
   * @memberof OnboardingForCms
   */
  toggle?: boolean
  /**
   *
   * @type {Array<Conditions>}
   * @memberof OnboardingForCms
   */
  conditions?: Array<Conditions>
  /**
   *
   * @type {Array<SlideForCms>}
   * @memberof OnboardingForCms
   */
  slides?: Array<SlideForCms>
}
/**
 *
 * @export
 * @interface OnboardingForCmsResponse
 */
export interface OnboardingForCmsResponse {
  /**
   *
   * @type {number}
   * @memberof OnboardingForCmsResponse
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OnboardingForCmsResponse
   */
  alias?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingForCmsResponse
   */
  fromAppVersion?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingForCmsResponse
   */
  toAppVersion?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingForCmsResponse
   */
  place?: string
  /**
   *
   * @type {Array<Platform>}
   * @memberof OnboardingForCmsResponse
   */
  platforms?: Array<Platform>
  /**
   *
   * @type {boolean}
   * @memberof OnboardingForCmsResponse
   */
  onlyForDeeplink?: boolean
  /**
   *
   * @type {number}
   * @memberof OnboardingForCmsResponse
   */
  triggerId?: number
  /**
   *
   * @type {string}
   * @memberof OnboardingForCmsResponse
   */
  triggerName?: string
  /**
   *
   * @type {boolean}
   * @memberof OnboardingForCmsResponse
   */
  toggle?: boolean
  /**
   *
   * @type {Array<Conditions>}
   * @memberof OnboardingForCmsResponse
   */
  conditions?: Array<Conditions>
  /**
   *
   * @type {Array<SlideForCms>}
   * @memberof OnboardingForCmsResponse
   */
  slides?: Array<SlideForCms>
}
/**
 *
 * @export
 * @interface OnboardingGetSlides
 */
export interface OnboardingGetSlides {
  /**
   *
   * @type {number}
   * @memberof OnboardingGetSlides
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OnboardingGetSlides
   */
  alias?: string
}
/**
 *
 * @export
 * @interface OnboardingModelResponse
 */
export interface OnboardingModelResponse {
  /**
   *
   * @type {number}
   * @memberof OnboardingModelResponse
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof OnboardingModelResponse
   */
  alias?: string
  /**
   *
   * @type {Array<SlideDto>}
   * @memberof OnboardingModelResponse
   */
  slides?: Array<SlideDto>
}
/**
 *
 * @export
 * @interface OnboardingRequest
 */
export interface OnboardingRequest {
  /**
   *
   * @type {string}
   * @memberof OnboardingRequest
   */
  alias: string
  /**
   *
   * @type {string}
   * @memberof OnboardingRequest
   */
  fromAppVersion: string
  /**
   *
   * @type {string}
   * @memberof OnboardingRequest
   */
  toAppVersion?: string
  /**
   *
   * @type {string}
   * @memberof OnboardingRequest
   */
  place?: string
  /**
   *
   * @type {Array<number>}
   * @memberof OnboardingRequest
   */
  platforms: Array<number>
  /**
   *
   * @type {boolean}
   * @memberof OnboardingRequest
   */
  onlyForDeeplink: boolean
  /**
   *
   * @type {number}
   * @memberof OnboardingRequest
   */
  triggerId?: number
  /**
   *
   * @type {boolean}
   * @memberof OnboardingRequest
   */
  toggle: boolean
  /**
   *
   * @type {Array<Slide>}
   * @memberof OnboardingRequest
   */
  slides?: Array<Slide>
  /**
   *
   * @type {Array<Conditions>}
   * @memberof OnboardingRequest
   */
  conditions?: Array<Conditions>
}
/**
 *
 * @export
 * @interface OnboardingsAllCmsResponse
 */
export interface OnboardingsAllCmsResponse {
  /**
   *
   * @type {Array<OnboardingForCms>}
   * @memberof OnboardingsAllCmsResponse
   */
  onboardings?: Array<OnboardingForCms>
}
/**
 *
 * @export
 * @interface Param
 */
export interface Param {
  /**
   *
   * @type {number}
   * @memberof Param
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Param
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Param
   */
  title?: string
  /**
   *
   * @type {boolean}
   * @memberof Param
   */
  required?: boolean
  /**
   *
   * @type {string}
   * @memberof Param
   */
  cmsFieldType?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Param
   */
  predefinedValue?: Array<string>
}
/**
 *
 * @export
 * @interface ParamGetSlides
 */
export interface ParamGetSlides {
  /**
   *
   * @type {number}
   * @memberof ParamGetSlides
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ParamGetSlides
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ParamGetSlides
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof ParamGetSlides
   */
  cmsFieldType?: string
}
/**
 *
 * @export
 * @interface ParamRequest
 */
export interface ParamRequest {
  /**
   *
   * @type {number}
   * @memberof ParamRequest
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ParamRequest
   */
  value?: string
}
/**
 *
 * @export
 * @interface PlaceRequest
 */
export interface PlaceRequest {
  /**
   *
   * @type {string}
   * @memberof PlaceRequest
   */
  place: string
}
/**
 *
 * @export
 * @interface PlacesModel
 */
export interface PlacesModel {
  /**
   *
   * @type {Array<string>}
   * @memberof PlacesModel
   */
  places?: Array<string>
}
/**
 *
 * @export
 * @interface Platform
 */
export interface Platform {
  /**
   *
   * @type {number}
   * @memberof Platform
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Platform
   */
  name?: string
}
/**
 *
 * @export
 * @interface PlatformsModelResponse
 */
export interface PlatformsModelResponse {
  /**
   *
   * @type {Array<Platform>}
   * @memberof PlatformsModelResponse
   */
  platforms?: Array<Platform>
}
/**
 *
 * @export
 * @interface SaveOrUpdateMessage
 */
export interface SaveOrUpdateMessage {
  /**
   *
   * @type {number}
   * @memberof SaveOrUpdateMessage
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof SaveOrUpdateMessage
   */
  message?: string
}
/**
 *
 * @export
 * @interface Slide
 */
export interface Slide {
  /**
   *
   * @type {number}
   * @memberof Slide
   */
  id?: number
  /**
   *
   * @type {number}
   * @memberof Slide
   */
  order?: number
}
/**
 *
 * @export
 * @interface SlideDto
 */
export interface SlideDto {
  /**
   *
   * @type {string}
   * @memberof SlideDto
   */
  offerId?: string
  /**
   *
   * @type {number}
   * @memberof SlideDto
   */
  slideId?: number
  /**
   *
   * @type {string}
   * @memberof SlideDto
   */
  alias?: string
  /**
   *
   * @type {string}
   * @memberof SlideDto
   */
  template?: string
  /**
   *
   * @type {Array<View>}
   * @memberof SlideDto
   */
  views?: Array<View>
}
/**
 *
 * @export
 * @interface SlideForCms
 */
export interface SlideForCms {
  /**
   *
   * @type {number}
   * @memberof SlideForCms
   */
  slideId?: number
  /**
   *
   * @type {number}
   * @memberof SlideForCms
   */
  order?: number
  /**
   *
   * @type {string}
   * @memberof SlideForCms
   */
  alias?: string
}
/**
 *
 * @export
 * @interface SlideModel
 */
export interface SlideModel {
  /**
   *
   * @type {number}
   * @memberof SlideModel
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof SlideModel
   */
  alias?: string
  /**
   *
   * @type {TemplateGetSlides}
   * @memberof SlideModel
   */
  template?: TemplateGetSlides
  /**
   *
   * @type {Array<ViewModel>}
   * @memberof SlideModel
   */
  views?: Array<ViewModel>
  /**
   *
   * @type {Array<OnboardingGetSlides>}
   * @memberof SlideModel
   */
  onboardings?: Array<OnboardingGetSlides>
}
/**
 *
 * @export
 * @interface SlideRequest
 */
export interface SlideRequest {
  /**
   *
   * @type {string}
   * @memberof SlideRequest
   */
  alias?: string
  /**
   *
   * @type {Array<ViewRequest>}
   * @memberof SlideRequest
   */
  views?: Array<ViewRequest>
  /**
   *
   * @type {number}
   * @memberof SlideRequest
   */
  template_id?: number
}
/**
 *
 * @export
 * @interface SlidesGetAllResponse
 */
export interface SlidesGetAllResponse {
  /**
   *
   * @type {Array<SlideModel>}
   * @memberof SlidesGetAllResponse
   */
  slides?: Array<SlideModel>
}
/**
 *
 * @export
 * @interface SlidesWithoutViewsModel
 */
export interface SlidesWithoutViewsModel {
  /**
   *
   * @type {number}
   * @memberof SlidesWithoutViewsModel
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof SlidesWithoutViewsModel
   */
  alias?: string
}
/**
 *
 * @export
 * @interface SlidesWithoutViewsResponse
 */
export interface SlidesWithoutViewsResponse {
  /**
   *
   * @type {Array<SlidesWithoutViewsModel>}
   * @memberof SlidesWithoutViewsResponse
   */
  slides?: Array<SlidesWithoutViewsModel>
}
/**
 *
 * @export
 * @interface Template
 */
export interface Template {
  /**
   *
   * @type {number}
   * @memberof Template
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Template
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof Template
   */
  exampleImg?: string
  /**
   *
   * @type {Array<ViewCms>}
   * @memberof Template
   */
  views?: Array<ViewCms>
}
/**
 *
 * @export
 * @interface TemplateByIdResponse
 */
export interface TemplateByIdResponse {
  /**
   *
   * @type {number}
   * @memberof TemplateByIdResponse
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof TemplateByIdResponse
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TemplateByIdResponse
   */
  exampleImg?: string
  /**
   *
   * @type {Array<ViewCms>}
   * @memberof TemplateByIdResponse
   */
  views?: Array<ViewCms>
}
/**
 *
 * @export
 * @interface TemplateGetSlides
 */
export interface TemplateGetSlides {
  /**
   *
   * @type {number}
   * @memberof TemplateGetSlides
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof TemplateGetSlides
   */
  name?: string
}
/**
 *
 * @export
 * @interface TemplatesResponse
 */
export interface TemplatesResponse {
  /**
   *
   * @type {Array<Template>}
   * @memberof TemplatesResponse
   */
  templates?: Array<Template>
}
/**
 *
 * @export
 * @interface Trigger
 */
export interface Trigger {
  /**
   *
   * @type {number}
   * @memberof Trigger
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof Trigger
   */
  name?: string
}
/**
 *
 * @export
 * @interface TriggerParamsCms
 */
export interface TriggerParamsCms {
  /**
   *
   * @type {number}
   * @memberof TriggerParamsCms
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof TriggerParamsCms
   */
  name?: string
}
/**
 *
 * @export
 * @interface TriggerParamsResponse
 */
export interface TriggerParamsResponse {
  /**
   *
   * @type {Array<TriggerParamsCms>}
   * @memberof TriggerParamsResponse
   */
  triggerParamName?: Array<TriggerParamsCms>
}
/**
 *
 * @export
 * @interface TriggersResponse
 */
export interface TriggersResponse {
  /**
   *
   * @type {Array<Trigger>}
   * @memberof TriggersResponse
   */
  triggers?: Array<Trigger>
}
/**
 *
 * @export
 * @interface View
 */
export interface View {
  /**
   *
   * @type {string}
   * @memberof View
   */
  viewName?: string
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof View
   */
  params?: { [key: string]: string }
}
/**
 *
 * @export
 * @interface ViewCms
 */
export interface ViewCms {
  /**
   *
   * @type {number}
   * @memberof ViewCms
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ViewCms
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ViewCms
   */
  title?: string
  /**
   *
   * @type {boolean}
   * @memberof ViewCms
   */
  required?: boolean
  /**
   *
   * @type {Array<Param>}
   * @memberof ViewCms
   */
  params?: Array<Param>
}
/**
 *
 * @export
 * @interface ViewModel
 */
export interface ViewModel {
  /**
   *
   * @type {number}
   * @memberof ViewModel
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ViewModel
   */
  name?: string
  /**
   *
   * @type {Array<ParamGetSlides>}
   * @memberof ViewModel
   */
  params?: Array<ParamGetSlides>
}
/**
 *
 * @export
 * @interface ViewRequest
 */
export interface ViewRequest {
  /**
   *
   * @type {number}
   * @memberof ViewRequest
   */
  id?: number
  /**
   *
   * @type {Array<ParamRequest>}
   * @memberof ViewRequest
   */
  params?: Array<ParamRequest>
}

/**
 * ConditionsControllerApi - axios parameter creator
 * @export
 */
export const ConditionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableConditions(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/availableConditions`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ConditionsControllerApi - functional programming interface
 * @export
 */
export const ConditionsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableConditions(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableConditionsResponse> {
      const localVarAxiosArgs = ConditionsControllerApiAxiosParamCreator(configuration).getAvailableConditions(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ConditionsControllerApi - factory interface
 * @export
 */
export const ConditionsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableConditions(options?: any): AxiosPromise<AvailableConditionsResponse> {
      return ConditionsControllerApiFp(configuration).getAvailableConditions(options)(axios, basePath)
    },
  }
}

/**
 * ConditionsControllerApi - object-oriented interface
 * @export
 * @class ConditionsControllerApi
 * @extends {BaseAPI}
 */
export class ConditionsControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConditionsControllerApi
   */
  public getAvailableConditions(options?: any) {
    return ConditionsControllerApiFp(this.configuration).getAvailableConditions(options)(this.axios, this.basePath)
  }
}

/**
 * HealthCheckControllerApi - axios parameter creator
 * @export
 */
export const HealthCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveness(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/live`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readiness(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/read`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * HealthCheckControllerApi - functional programming interface
 * @export
 */
export const HealthCheckControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
      const localVarAxiosArgs = HealthCheckControllerApiAxiosParamCreator(configuration).check(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveness(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health> {
      const localVarAxiosArgs = HealthCheckControllerApiAxiosParamCreator(configuration).liveness(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readiness(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health> {
      const localVarAxiosArgs = HealthCheckControllerApiAxiosParamCreator(configuration).readiness(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * HealthCheckControllerApi - factory interface
 * @export
 */
export const HealthCheckControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    check(options?: any): AxiosPromise<string> {
      return HealthCheckControllerApiFp(configuration).check(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveness(options?: any): AxiosPromise<Health> {
      return HealthCheckControllerApiFp(configuration).liveness(options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readiness(options?: any): AxiosPromise<Health> {
      return HealthCheckControllerApiFp(configuration).readiness(options)(axios, basePath)
    },
  }
}

/**
 * HealthCheckControllerApi - object-oriented interface
 * @export
 * @class HealthCheckControllerApi
 * @extends {BaseAPI}
 */
export class HealthCheckControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckControllerApi
   */
  public check(options?: any) {
    return HealthCheckControllerApiFp(this.configuration).check(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckControllerApi
   */
  public liveness(options?: any) {
    return HealthCheckControllerApiFp(this.configuration).liveness(options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckControllerApi
   */
  public readiness(options?: any) {
    return HealthCheckControllerApiFp(this.configuration).readiness(options)(this.axios, this.basePath)
  }
}

/**
 * ImagesControllerApi - axios parameter creator
 * @export
 */
export const ImagesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} clientId
     * @param {number} slidesId
     * @param {number} viewsId
     * @param {number} paramsId
     * @param {string} [xRequestId]
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {string} [modelPhone]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImageByUserResponse(
      clientId: string,
      slidesId: number,
      viewsId: number,
      paramsId: number,
      xRequestId?: string,
      platform?: string,
      appVersion?: string,
      modelPhone?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'clientId' is not null or undefined
      if (clientId === null || clientId === undefined) {
        throw new RequiredError(
          'clientId',
          'Required parameter clientId was null or undefined when calling getImageByUserResponse.'
        )
      }
      // verify required parameter 'slidesId' is not null or undefined
      if (slidesId === null || slidesId === undefined) {
        throw new RequiredError(
          'slidesId',
          'Required parameter slidesId was null or undefined when calling getImageByUserResponse.'
        )
      }
      // verify required parameter 'viewsId' is not null or undefined
      if (viewsId === null || viewsId === undefined) {
        throw new RequiredError(
          'viewsId',
          'Required parameter viewsId was null or undefined when calling getImageByUserResponse.'
        )
      }
      // verify required parameter 'paramsId' is not null or undefined
      if (paramsId === null || paramsId === undefined) {
        throw new RequiredError(
          'paramsId',
          'Required parameter paramsId was null or undefined when calling getImageByUserResponse.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/slides/{slidesId}/views/{viewsId}/params/{paramsId}`
        .replace(`{${'slidesId'}}`, encodeURIComponent(String(slidesId)))
        .replace(`{${'viewsId'}}`, encodeURIComponent(String(viewsId)))
        .replace(`{${'paramsId'}}`, encodeURIComponent(String(paramsId)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (xRequestId !== undefined && xRequestId !== null) {
        localVarHeaderParameter['X-Request-Id'] = String(xRequestId)
      }

      if (platform !== undefined && platform !== null) {
        localVarHeaderParameter['platform'] = String(platform)
      }

      if (appVersion !== undefined && appVersion !== null) {
        localVarHeaderParameter['app-version'] = String(appVersion)
      }

      if (clientId !== undefined && clientId !== null) {
        localVarHeaderParameter['Client-id'] = String(clientId)
      }

      if (modelPhone !== undefined && modelPhone !== null) {
        localVarHeaderParameter['Model-phone'] = String(modelPhone)
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ImagesControllerApi - functional programming interface
 * @export
 */
export const ImagesControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} clientId
     * @param {number} slidesId
     * @param {number} viewsId
     * @param {number} paramsId
     * @param {string} [xRequestId]
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {string} [modelPhone]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImageByUserResponse(
      clientId: string,
      slidesId: number,
      viewsId: number,
      paramsId: number,
      xRequestId?: string,
      platform?: string,
      appVersion?: string,
      modelPhone?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ImagesControllerApiAxiosParamCreator(configuration).getImageByUserResponse(
        clientId,
        slidesId,
        viewsId,
        paramsId,
        xRequestId,
        platform,
        appVersion,
        modelPhone,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ImagesControllerApi - factory interface
 * @export
 */
export const ImagesControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} clientId
     * @param {number} slidesId
     * @param {number} viewsId
     * @param {number} paramsId
     * @param {string} [xRequestId]
     * @param {string} [platform]
     * @param {string} [appVersion]
     * @param {string} [modelPhone]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImageByUserResponse(
      clientId: string,
      slidesId: number,
      viewsId: number,
      paramsId: number,
      xRequestId?: string,
      platform?: string,
      appVersion?: string,
      modelPhone?: string,
      options?: any
    ): AxiosPromise<any> {
      return ImagesControllerApiFp(configuration).getImageByUserResponse(
        clientId,
        slidesId,
        viewsId,
        paramsId,
        xRequestId,
        platform,
        appVersion,
        modelPhone,
        options
      )(axios, basePath)
    },
  }
}

/**
 * ImagesControllerApi - object-oriented interface
 * @export
 * @class ImagesControllerApi
 * @extends {BaseAPI}
 */
export class ImagesControllerApi extends BaseAPI {
  /**
   *
   * @param {string} clientId
   * @param {number} slidesId
   * @param {number} viewsId
   * @param {number} paramsId
   * @param {string} [xRequestId]
   * @param {string} [platform]
   * @param {string} [appVersion]
   * @param {string} [modelPhone]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImagesControllerApi
   */
  public getImageByUserResponse(
    clientId: string,
    slidesId: number,
    viewsId: number,
    paramsId: number,
    xRequestId?: string,
    platform?: string,
    appVersion?: string,
    modelPhone?: string,
    options?: any
  ) {
    return ImagesControllerApiFp(this.configuration).getImageByUserResponse(
      clientId,
      slidesId,
      viewsId,
      paramsId,
      xRequestId,
      platform,
      appVersion,
      modelPhone,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * OnboardingControllerApi - axios parameter creator
 * @export
 */
export const OnboardingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} platform
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboarding(platform: string, id: number, options: any = {}): RequestArgs {
      // verify required parameter 'platform' is not null or undefined
      if (platform === null || platform === undefined) {
        throw new RequiredError(
          'platform',
          'Required parameter platform was null or undefined when calling getOnboarding.'
        )
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getOnboarding.')
      }
      const localVarPath = `/pl-onboarding/v1/onboardings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (platform !== undefined && platform !== null) {
        localVarHeaderParameter['platform'] = String(platform)
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} platform
     * @param {string} place
     * @param {string} [productId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboardings(platform: string, place: string, productId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'platform' is not null or undefined
      if (platform === null || platform === undefined) {
        throw new RequiredError(
          'platform',
          'Required parameter platform was null or undefined when calling getOnboardings.'
        )
      }
      // verify required parameter 'place' is not null or undefined
      if (place === null || place === undefined) {
        throw new RequiredError('place', 'Required parameter place was null or undefined when calling getOnboardings.')
      }
      const localVarPath = `/pl-onboarding/v1/onboardings`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (place !== undefined) {
        localVarQueryParameter['place'] = place
      }

      if (productId !== undefined) {
        localVarQueryParameter['productId'] = productId
      }

      if (platform !== undefined && platform !== null) {
        localVarHeaderParameter['platform'] = String(platform)
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OnboardingControllerApi - functional programming interface
 * @export
 */
export const OnboardingControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} platform
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboarding(
      platform: string,
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingModelResponse> {
      const localVarAxiosArgs = OnboardingControllerApiAxiosParamCreator(configuration).getOnboarding(
        platform,
        id,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {string} platform
     * @param {string} place
     * @param {string} [productId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboardings(
      platform: string,
      place: string,
      productId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingModelResponse> {
      const localVarAxiosArgs = OnboardingControllerApiAxiosParamCreator(configuration).getOnboardings(
        platform,
        place,
        productId,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OnboardingControllerApi - factory interface
 * @export
 */
export const OnboardingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} platform
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboarding(platform: string, id: number, options?: any): AxiosPromise<OnboardingModelResponse> {
      return OnboardingControllerApiFp(configuration).getOnboarding(platform, id, options)(axios, basePath)
    },
    /**
     *
     * @param {string} platform
     * @param {string} place
     * @param {string} [productId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOnboardings(
      platform: string,
      place: string,
      productId?: string,
      options?: any
    ): AxiosPromise<OnboardingModelResponse> {
      return OnboardingControllerApiFp(configuration).getOnboardings(
        platform,
        place,
        productId,
        options
      )(axios, basePath)
    },
  }
}

/**
 * OnboardingControllerApi - object-oriented interface
 * @export
 * @class OnboardingControllerApi
 * @extends {BaseAPI}
 */
export class OnboardingControllerApi extends BaseAPI {
  /**
   *
   * @param {string} platform
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingControllerApi
   */
  public getOnboarding(platform: string, id: number, options?: any) {
    return OnboardingControllerApiFp(this.configuration).getOnboarding(platform, id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {string} platform
   * @param {string} place
   * @param {string} [productId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingControllerApi
   */
  public getOnboardings(platform: string, place: string, productId?: string, options?: any) {
    return OnboardingControllerApiFp(this.configuration).getOnboardings(
      platform,
      place,
      productId,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * OnboardingForCmsControllerApi - axios parameter creator
 * @export
 */
export const OnboardingForCmsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOnboarding(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteOnboarding.')
      }
      const localVarPath = `/pl-onboarding/v1/onboardings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [alias]
     * @param {Array<number>} [platforms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(limit: number, offset: number, alias?: string, platforms?: Array<number>, options: any = {}): RequestArgs {
      // verify required parameter 'limit' is not null or undefined
      if (limit === null || limit === undefined) {
        throw new RequiredError('limit', 'Required parameter limit was null or undefined when calling getAll.')
      }
      // verify required parameter 'offset' is not null or undefined
      if (offset === null || offset === undefined) {
        throw new RequiredError('offset', 'Required parameter offset was null or undefined when calling getAll.')
      }
      const localVarPath = `/pl-onboarding/v1/onboardingsForCms`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (alias !== undefined) {
        localVarQueryParameter['alias'] = alias
      }

      if (platforms) {
        localVarQueryParameter['platforms'] = platforms
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getById.')
      }
      const localVarPath = `/pl-onboarding/v1/onboardingsForCms/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {OnboardingRequest} onboardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOnboardings(onboardingRequest: OnboardingRequest, options: any = {}): RequestArgs {
      // verify required parameter 'onboardingRequest' is not null or undefined
      if (onboardingRequest === null || onboardingRequest === undefined) {
        throw new RequiredError(
          'onboardingRequest',
          'Required parameter onboardingRequest was null or undefined when calling postOnboardings.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/onboardings`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof onboardingRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(onboardingRequest !== undefined ? onboardingRequest : {})
        : onboardingRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {OnboardingRequest} onboardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOnboardings(id: number, onboardingRequest: OnboardingRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateOnboardings.')
      }
      // verify required parameter 'onboardingRequest' is not null or undefined
      if (onboardingRequest === null || onboardingRequest === undefined) {
        throw new RequiredError(
          'onboardingRequest',
          'Required parameter onboardingRequest was null or undefined when calling updateOnboardings.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/onboardings/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof onboardingRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(onboardingRequest !== undefined ? onboardingRequest : {})
        : onboardingRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * OnboardingForCmsControllerApi - functional programming interface
 * @export
 */
export const OnboardingForCmsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOnboarding(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponse> {
      const localVarAxiosArgs = OnboardingForCmsControllerApiAxiosParamCreator(configuration).deleteOnboarding(
        id,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [alias]
     * @param {Array<number>} [platforms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(
      limit: number,
      offset: number,
      alias?: string,
      platforms?: Array<number>,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingsAllCmsResponse> {
      const localVarAxiosArgs = OnboardingForCmsControllerApiAxiosParamCreator(configuration).getAll(
        limit,
        offset,
        alias,
        platforms,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingForCmsResponse> {
      const localVarAxiosArgs = OnboardingForCmsControllerApiAxiosParamCreator(configuration).getById(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {OnboardingRequest} onboardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOnboardings(
      onboardingRequest: OnboardingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrUpdateMessage> {
      const localVarAxiosArgs = OnboardingForCmsControllerApiAxiosParamCreator(configuration).postOnboardings(
        onboardingRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} id
     * @param {OnboardingRequest} onboardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOnboardings(
      id: number,
      onboardingRequest: OnboardingRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrUpdateMessage> {
      const localVarAxiosArgs = OnboardingForCmsControllerApiAxiosParamCreator(configuration).updateOnboardings(
        id,
        onboardingRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * OnboardingForCmsControllerApi - factory interface
 * @export
 */
export const OnboardingForCmsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOnboarding(id: number, options?: any): AxiosPromise<DeleteResponse> {
      return OnboardingForCmsControllerApiFp(configuration).deleteOnboarding(id, options)(axios, basePath)
    },
    /**
     *
     * @param {number} limit
     * @param {number} offset
     * @param {string} [alias]
     * @param {Array<number>} [platforms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll(
      limit: number,
      offset: number,
      alias?: string,
      platforms?: Array<number>,
      options?: any
    ): AxiosPromise<OnboardingsAllCmsResponse> {
      return OnboardingForCmsControllerApiFp(configuration).getAll(
        limit,
        offset,
        alias,
        platforms,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getById(id: number, options?: any): AxiosPromise<OnboardingForCmsResponse> {
      return OnboardingForCmsControllerApiFp(configuration).getById(id, options)(axios, basePath)
    },
    /**
     *
     * @param {OnboardingRequest} onboardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOnboardings(onboardingRequest: OnboardingRequest, options?: any): AxiosPromise<SaveOrUpdateMessage> {
      return OnboardingForCmsControllerApiFp(configuration).postOnboardings(onboardingRequest, options)(axios, basePath)
    },
    /**
     *
     * @param {number} id
     * @param {OnboardingRequest} onboardingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateOnboardings(
      id: number,
      onboardingRequest: OnboardingRequest,
      options?: any
    ): AxiosPromise<SaveOrUpdateMessage> {
      return OnboardingForCmsControllerApiFp(configuration).updateOnboardings(
        id,
        onboardingRequest,
        options
      )(axios, basePath)
    },
  }
}

/**
 * OnboardingForCmsControllerApi - object-oriented interface
 * @export
 * @class OnboardingForCmsControllerApi
 * @extends {BaseAPI}
 */
export class OnboardingForCmsControllerApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingForCmsControllerApi
   */
  public deleteOnboarding(id: number, options?: any) {
    return OnboardingForCmsControllerApiFp(this.configuration).deleteOnboarding(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {number} limit
   * @param {number} offset
   * @param {string} [alias]
   * @param {Array<number>} [platforms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingForCmsControllerApi
   */
  public getAll(limit: number, offset: number, alias?: string, platforms?: Array<number>, options?: any) {
    return OnboardingForCmsControllerApiFp(this.configuration).getAll(
      limit,
      offset,
      alias,
      platforms,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingForCmsControllerApi
   */
  public getById(id: number, options?: any) {
    return OnboardingForCmsControllerApiFp(this.configuration).getById(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {OnboardingRequest} onboardingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingForCmsControllerApi
   */
  public postOnboardings(onboardingRequest: OnboardingRequest, options?: any) {
    return OnboardingForCmsControllerApiFp(this.configuration).postOnboardings(onboardingRequest, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @param {number} id
   * @param {OnboardingRequest} onboardingRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OnboardingForCmsControllerApi
   */
  public updateOnboardings(id: number, onboardingRequest: OnboardingRequest, options?: any) {
    return OnboardingForCmsControllerApiFp(this.configuration).updateOnboardings(
      id,
      onboardingRequest,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * PlacesControllerApi - axios parameter creator
 * @export
 */
export const PlacesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PlaceRequest} placeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPlace(placeRequest: PlaceRequest, options: any = {}): RequestArgs {
      // verify required parameter 'placeRequest' is not null or undefined
      if (placeRequest === null || placeRequest === undefined) {
        throw new RequiredError(
          'placeRequest',
          'Required parameter placeRequest was null or undefined when calling addPlace.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/places`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof placeRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(placeRequest !== undefined ? placeRequest : {})
        : placeRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlaces(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/places`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PlacesControllerApi - functional programming interface
 * @export
 */
export const PlacesControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {PlaceRequest} placeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPlace(
      placeRequest: PlaceRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = PlacesControllerApiAxiosParamCreator(configuration).addPlace(placeRequest, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlaces(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlacesModel> {
      const localVarAxiosArgs = PlacesControllerApiAxiosParamCreator(configuration).getPlaces(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PlacesControllerApi - factory interface
 * @export
 */
export const PlacesControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {PlaceRequest} placeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPlace(placeRequest: PlaceRequest, options?: any): AxiosPromise<void> {
      return PlacesControllerApiFp(configuration).addPlace(placeRequest, options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPlaces(options?: any): AxiosPromise<PlacesModel> {
      return PlacesControllerApiFp(configuration).getPlaces(options)(axios, basePath)
    },
  }
}

/**
 * PlacesControllerApi - object-oriented interface
 * @export
 * @class PlacesControllerApi
 * @extends {BaseAPI}
 */
export class PlacesControllerApi extends BaseAPI {
  /**
   *
   * @param {PlaceRequest} placeRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlacesControllerApi
   */
  public addPlace(placeRequest: PlaceRequest, options?: any) {
    return PlacesControllerApiFp(this.configuration).addPlace(placeRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlacesControllerApi
   */
  public getPlaces(options?: any) {
    return PlacesControllerApiFp(this.configuration).getPlaces(options)(this.axios, this.basePath)
  }
}

/**
 * PlatformsControllerApi - axios parameter creator
 * @export
 */
export const PlatformsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateById1(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/platforms`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PlatformsControllerApi - functional programming interface
 * @export
 */
export const PlatformsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateById1(
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlatformsModelResponse> {
      const localVarAxiosArgs = PlatformsControllerApiAxiosParamCreator(configuration).getTemplateById1(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * PlatformsControllerApi - factory interface
 * @export
 */
export const PlatformsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateById1(options?: any): AxiosPromise<PlatformsModelResponse> {
      return PlatformsControllerApiFp(configuration).getTemplateById1(options)(axios, basePath)
    },
  }
}

/**
 * PlatformsControllerApi - object-oriented interface
 * @export
 * @class PlatformsControllerApi
 * @extends {BaseAPI}
 */
export class PlatformsControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlatformsControllerApi
   */
  public getTemplateById1(options?: any) {
    return PlatformsControllerApiFp(this.configuration).getTemplateById1(options)(this.axios, this.basePath)
  }
}

/**
 * SlidesForCmsControllerApi - axios parameter creator
 * @export
 */
export const SlidesForCmsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SlideRequest} slideRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSlides(slideRequest: SlideRequest, options: any = {}): RequestArgs {
      // verify required parameter 'slideRequest' is not null or undefined
      if (slideRequest === null || slideRequest === undefined) {
        throw new RequiredError(
          'slideRequest',
          'Required parameter slideRequest was null or undefined when calling addSlides.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/slides`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof slideRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(slideRequest !== undefined ? slideRequest : {})
        : slideRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSlides(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling deleteSlides.')
      }
      const localVarPath = `/pl-onboarding/v1/slides/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [slideAlias]
     * @param {string} [onboardingAlias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlides(
      limit?: number,
      offset?: number,
      slideAlias?: string,
      onboardingAlias?: string,
      options: any = {}
    ): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/slides`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset
      }

      if (slideAlias !== undefined) {
        localVarQueryParameter['slideAlias'] = slideAlias
      }

      if (onboardingAlias !== undefined) {
        localVarQueryParameter['onboardingAlias'] = onboardingAlias
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlidesById(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getSlidesById.')
      }
      const localVarPath = `/pl-onboarding/v1/slides/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [alias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlidesWithoutViews(alias?: string, options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/slidesList`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (alias !== undefined) {
        localVarQueryParameter['alias'] = alias
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {SlideRequest} slideRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSlides(id: number, slideRequest: SlideRequest, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling updateSlides.')
      }
      // verify required parameter 'slideRequest' is not null or undefined
      if (slideRequest === null || slideRequest === undefined) {
        throw new RequiredError(
          'slideRequest',
          'Required parameter slideRequest was null or undefined when calling updateSlides.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/slides/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof slideRequest !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(slideRequest !== undefined ? slideRequest : {})
        : slideRequest || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SlidesForCmsControllerApi - functional programming interface
 * @export
 */
export const SlidesForCmsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SlideRequest} slideRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSlides(
      slideRequest: SlideRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrUpdateMessage> {
      const localVarAxiosArgs = SlidesForCmsControllerApiAxiosParamCreator(configuration).addSlides(
        slideRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSlides(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrUpdateMessage> {
      const localVarAxiosArgs = SlidesForCmsControllerApiAxiosParamCreator(configuration).deleteSlides(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [slideAlias]
     * @param {string} [onboardingAlias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlides(
      limit?: number,
      offset?: number,
      slideAlias?: string,
      onboardingAlias?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlidesGetAllResponse> {
      const localVarAxiosArgs = SlidesForCmsControllerApiAxiosParamCreator(configuration).getSlides(
        limit,
        offset,
        slideAlias,
        onboardingAlias,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlidesById(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlideModel> {
      const localVarAxiosArgs = SlidesForCmsControllerApiAxiosParamCreator(configuration).getSlidesById(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {string} [alias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlidesWithoutViews(
      alias?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlidesWithoutViewsResponse> {
      const localVarAxiosArgs = SlidesForCmsControllerApiAxiosParamCreator(configuration).getSlidesWithoutViews(
        alias,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {number} id
     * @param {SlideRequest} slideRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSlides(
      id: number,
      slideRequest: SlideRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveOrUpdateMessage> {
      const localVarAxiosArgs = SlidesForCmsControllerApiAxiosParamCreator(configuration).updateSlides(
        id,
        slideRequest,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * SlidesForCmsControllerApi - factory interface
 * @export
 */
export const SlidesForCmsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {SlideRequest} slideRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addSlides(slideRequest: SlideRequest, options?: any): AxiosPromise<SaveOrUpdateMessage> {
      return SlidesForCmsControllerApiFp(configuration).addSlides(slideRequest, options)(axios, basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteSlides(id: number, options?: any): AxiosPromise<SaveOrUpdateMessage> {
      return SlidesForCmsControllerApiFp(configuration).deleteSlides(id, options)(axios, basePath)
    },
    /**
     *
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [slideAlias]
     * @param {string} [onboardingAlias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlides(
      limit?: number,
      offset?: number,
      slideAlias?: string,
      onboardingAlias?: string,
      options?: any
    ): AxiosPromise<SlidesGetAllResponse> {
      return SlidesForCmsControllerApiFp(configuration).getSlides(
        limit,
        offset,
        slideAlias,
        onboardingAlias,
        options
      )(axios, basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlidesById(id: number, options?: any): AxiosPromise<SlideModel> {
      return SlidesForCmsControllerApiFp(configuration).getSlidesById(id, options)(axios, basePath)
    },
    /**
     *
     * @param {string} [alias]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSlidesWithoutViews(alias?: string, options?: any): AxiosPromise<SlidesWithoutViewsResponse> {
      return SlidesForCmsControllerApiFp(configuration).getSlidesWithoutViews(alias, options)(axios, basePath)
    },
    /**
     *
     * @param {number} id
     * @param {SlideRequest} slideRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateSlides(id: number, slideRequest: SlideRequest, options?: any): AxiosPromise<SaveOrUpdateMessage> {
      return SlidesForCmsControllerApiFp(configuration).updateSlides(id, slideRequest, options)(axios, basePath)
    },
  }
}

/**
 * SlidesForCmsControllerApi - object-oriented interface
 * @export
 * @class SlidesForCmsControllerApi
 * @extends {BaseAPI}
 */
export class SlidesForCmsControllerApi extends BaseAPI {
  /**
   *
   * @param {SlideRequest} slideRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlidesForCmsControllerApi
   */
  public addSlides(slideRequest: SlideRequest, options?: any) {
    return SlidesForCmsControllerApiFp(this.configuration).addSlides(slideRequest, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlidesForCmsControllerApi
   */
  public deleteSlides(id: number, options?: any) {
    return SlidesForCmsControllerApiFp(this.configuration).deleteSlides(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {number} [limit]
   * @param {number} [offset]
   * @param {string} [slideAlias]
   * @param {string} [onboardingAlias]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlidesForCmsControllerApi
   */
  public getSlides(limit?: number, offset?: number, slideAlias?: string, onboardingAlias?: string, options?: any) {
    return SlidesForCmsControllerApiFp(this.configuration).getSlides(
      limit,
      offset,
      slideAlias,
      onboardingAlias,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlidesForCmsControllerApi
   */
  public getSlidesById(id: number, options?: any) {
    return SlidesForCmsControllerApiFp(this.configuration).getSlidesById(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {string} [alias]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlidesForCmsControllerApi
   */
  public getSlidesWithoutViews(alias?: string, options?: any) {
    return SlidesForCmsControllerApiFp(this.configuration).getSlidesWithoutViews(alias, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @param {number} id
   * @param {SlideRequest} slideRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SlidesForCmsControllerApi
   */
  public updateSlides(id: number, slideRequest: SlideRequest, options?: any) {
    return SlidesForCmsControllerApiFp(this.configuration).updateSlides(
      id,
      slideRequest,
      options
    )(this.axios, this.basePath)
  }
}

/**
 * TemplateControllerApi - axios parameter creator
 * @export
 */
export const TemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateById(id: number, options: any = {}): RequestArgs {
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError('id', 'Required parameter id was null or undefined when calling getTemplateById.')
      }
      const localVarPath = `/pl-onboarding/v1/templates/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/templates`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TemplateControllerApi - functional programming interface
 * @export
 */
export const TemplateControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateById(
      id: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateByIdResponse> {
      const localVarAxiosArgs = TemplateControllerApiAxiosParamCreator(configuration).getTemplateById(id, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesResponse> {
      const localVarAxiosArgs = TemplateControllerApiAxiosParamCreator(configuration).getTemplates(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * TemplateControllerApi - factory interface
 * @export
 */
export const TemplateControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplateById(id: number, options?: any): AxiosPromise<TemplateByIdResponse> {
      return TemplateControllerApiFp(configuration).getTemplateById(id, options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTemplates(options?: any): AxiosPromise<TemplatesResponse> {
      return TemplateControllerApiFp(configuration).getTemplates(options)(axios, basePath)
    },
  }
}

/**
 * TemplateControllerApi - object-oriented interface
 * @export
 * @class TemplateControllerApi
 * @extends {BaseAPI}
 */
export class TemplateControllerApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public getTemplateById(id: number, options?: any) {
    return TemplateControllerApiFp(this.configuration).getTemplateById(id, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TemplateControllerApi
   */
  public getTemplates(options?: any) {
    return TemplateControllerApiFp(this.configuration).getTemplates(options)(this.axios, this.basePath)
  }
}

/**
 * TriggersControllerApi - axios parameter creator
 * @export
 */
export const TriggersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} triggerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConditions(triggerId: number, options: any = {}): RequestArgs {
      // verify required parameter 'triggerId' is not null or undefined
      if (triggerId === null || triggerId === undefined) {
        throw new RequiredError(
          'triggerId',
          'Required parameter triggerId was null or undefined when calling getConditions.'
        )
      }
      const localVarPath = `/pl-onboarding/v1/conditions`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (triggerId !== undefined) {
        localVarQueryParameter['triggerId'] = triggerId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTriggers(options: any = {}): RequestArgs {
      const localVarPath = `/pl-onboarding/v1/triggers`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TriggersControllerApi - functional programming interface
 * @export
 */
export const TriggersControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} triggerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConditions(
      triggerId: number,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerParamsResponse> {
      const localVarAxiosArgs = TriggersControllerApiAxiosParamCreator(configuration).getConditions(triggerId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTriggers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggersResponse> {
      const localVarAxiosArgs = TriggersControllerApiAxiosParamCreator(configuration).getTriggers(options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * TriggersControllerApi - factory interface
 * @export
 */
export const TriggersControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {number} triggerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConditions(triggerId: number, options?: any): AxiosPromise<TriggerParamsResponse> {
      return TriggersControllerApiFp(configuration).getConditions(triggerId, options)(axios, basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTriggers(options?: any): AxiosPromise<TriggersResponse> {
      return TriggersControllerApiFp(configuration).getTriggers(options)(axios, basePath)
    },
  }
}

/**
 * TriggersControllerApi - object-oriented interface
 * @export
 * @class TriggersControllerApi
 * @extends {BaseAPI}
 */
export class TriggersControllerApi extends BaseAPI {
  /**
   *
   * @param {number} triggerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TriggersControllerApi
   */
  public getConditions(triggerId: number, options?: any) {
    return TriggersControllerApiFp(this.configuration).getConditions(triggerId, options)(this.axios, this.basePath)
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TriggersControllerApi
   */
  public getTriggers(options?: any) {
    return TriggersControllerApiFp(this.configuration).getTriggers(options)(this.axios, this.basePath)
  }
}
