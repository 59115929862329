import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Text, theme, useBreakpoint, useDidMount } from '@mtsbank/ui-kit';
import { styled } from '@mtsbank/common';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { getUnBelievaBle } from '@reducers/payment/payment';
import { selectUnBelievaBleState } from '@root/selectors/payment';
export const UnBelievaBle = () => {
  const [isAuth, setIsAuth] = useState(false);
  const dispatch = useDispatch();
  const {
    data: dataUnBelievaBle,
    error: errorUnBelievaBle
  } = useSelector(selectUnBelievaBleState);
  const {
    isSm,
    isMd,
    isLg
  } = useBreakpoint();
  const isDesktop = isLg || isMd || isSm;
  const descriptors = isDesktop ? 'Вы\u00A0находитесь в\u00A0режиме ограниченной функциональности. Для полного доступа к\u00A0продуктам МТС\u00A0Банка нажмите\u00A0"Выйти" и\u00A0заново авторизуйтесь в\u00A0личном кабинете.' : 'Вы\u00A0в\u00A0ограниченном режиме, для доступа к\u00A0продуктам банка авторизуйтесь повторно.';
  useDidMount(() => {
    if (authHelper.isAuthenticated()) {
      setIsAuth(true);
    }
  });
  useEffect(() => {
    if (isAuth && isEmpty(dataUnBelievaBle) && isEmpty(errorUnBelievaBle)) {
      dispatch(getUnBelievaBle());
    }
  }, [dataUnBelievaBle, dispatch, errorUnBelievaBle, isAuth]);
  if (!dataUnBelievaBle.isUnBelievable) {
    return null;
  }
  return <Wrapper>
      <WrapperText>
        <Text size="xl" fontWeight="medium" align="center">
          {descriptors}
        </Text>
      </WrapperText>
    </Wrapper>;
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacings.xs}px ${theme.spacings.xs}px 0;
  background-color: #f2f3f7;
`;
const WrapperText = styled.div`
  align-text: center;
  background-color: ${theme.colors.yellow.autofill};
  padding: ${theme.spacings.sm}px;
  width: 100%;
  border-radius: ${theme.spacings.sm}px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 100%;
  }
  @media (min-width: ${theme.breakpoints.sm}px) {
    width: 880px;
  }
  @media (min-width: ${theme.breakpoints.md}px) {
    width: 1152px;
  }
  @media (min-width: ${theme.breakpoints.lg}px) {
    width: 1292px;
  }
`;