import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { Field, Form, useFormikContext } from 'formik'
import indexOf from 'lodash/fp/indexOf'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/fp/debounce'
import isEqual from 'lodash/isEqual'
import { Button, formatMoney, Heading, theme, useToast } from '@mtsbank/ui-kit'
import { BankInfo, GetTransferListRes, Transfer } from '@open-api/ump/transfer-by-phone'
import { Binding } from '@open-api/ump/ewallet-profile'

import { validateField } from '@utils/formValidators/formValidators'
import { RootState } from '@root/reducers'
import { selectBindings } from '@selectors/processing'
import { useAppThunkDispatch } from '@root/store'

import { Content } from '@components/Content/Content'
import { MoneyInputField } from '@components/FormFields/MoneyInputField'
import { BankSuggestion, FieldsValues, FormFields, SBPdata } from '@components/SBP/types'
import { TextField } from '@components/FormFields/TextField'
import { Validator } from '@utils/formValidators/types'
import { authHelper } from '@root/utils/authHelper/AuthHelper'

import { RecipientBankField } from '@components/SBP/RecipientBankField'
import { fetchCheckTransfer, fetchTransferList, setIsTransferTypeSBP, runCheckTransfer } from '@reducers/sbp/sbp'
import {
  selectBankList,
  selectCheckTransferState,
  selectTransfer,
  selectTransferByPhoneConstraints,
  selectTransferList,
} from '@selectors/sbp'
import { ErrorResponse, ParamsFetchCheckTransfer, TransferByPhoneConstraints } from '@reducers/sbp/types'
import { FeeAndSum } from '@components/FeeAndSum/FeeAndSum'
import { ModalMapping } from '@components/Modals/ModalMapping'
import { ModalNames } from '@components/Modals/type'
import { AntifrodeErrorCodes } from '@components/Modals/AntifrodError/type'
import { convertPennyToBanknote } from '@components/SBP/ResultPage/utils'
import { getRecommendedBank, validateAmountWithFee } from '@components/SBP/utils'
import { DEFAULT_LIMITS, MTS_BANK_ID, CREDIT_CARD_FEE_MESSAGE } from '@components/SBP/constants'
import { isNumber } from '@utils/format'
import { defaultCurrencyAlias } from '@root/constants/currencyMapping'
import { AMOUNT_MIN_VALUE } from '@components/SBP/formConfig'
import { FeeNotice } from '@components/SBP/FeeNotice'
import { RecipientPhoneField } from '@components/SBP/RecipientPhoneField'
import { ToastButton } from '@components/SBP/ToastButton'
import { BindingsFieldHandler } from '@components/SBP/BindingsFieldHandler'
import { continueBtnClickGtm, prepareInputValueGtm, sendFieldGtm } from '@root/utils/gtm/sbp/events'
import { EventFieldNames } from '@root/utils/gtm/sbp/types'
import { toPenny, updateAmountValidator } from '@components/BindigsField/utils'
import { creditCardTypes } from '@components/BindigsField/constants'
import { ErrorToastReq } from '../Notice/ErrorToastReq'

export const PENNY = 100 as const
const SBP_TRANSFER_TYPE = 'SBP'

const SBER_ID = '100000000111' as const
const TRANSFER_LIST_DELAY = 1000
const CHECK_TRANSFER_DELAY = 1000

interface Props {
  formFields: FormFields
  setFormData: React.Dispatch<React.SetStateAction<SBPdata & { recipientBankName: string; recipientFIO: string }>>
}

export const FormikHandler: FC<Props> = ({ formFields, setFormData }) => {
  const dispatch = useAppThunkDispatch()
  const bindings = useSelector<RootState, Binding[]>(selectBindings)
  const transferList = useSelector<RootState, GetTransferListRes>(selectTransferList)
  const bankList = useSelector<RootState, BankInfo[]>(selectBankList)

  const { data: transferByPhoneConstraints } = useSelector<RootState, TransferByPhoneConstraints>(
    selectTransferByPhoneConstraints
  )
  const transfer = useSelector<RootState, Transfer>(selectTransfer)
  const { inProgress: inProgressCheckTransfer, error: errorCheckTransfer } = useSelector(selectCheckTransferState)
  const { values, isValid, dirty, setFieldValue, setFieldTouched, setFieldError, errors, touched } =
    useFormikContext<FieldsValues>()

  const { toast } = useToast()
  const [checkTransferToast, setCheckTransferToast] = useState<{ message: string; fn: () => void }>()

  const [antifrodPopup, setAntifrodPopup] = useState<{ errorCode: AntifrodeErrorCodes; message: string }>()

  const { userData } = authHelper

  const bindingsSelected = bindings.find(({ bindingId }) => bindingId === values.paymentSource) || {}
  const bankSelected = bankList.find(({ bankId }) => bankId === values.recipientBank.value) || {}
  const isCreditCard = indexOf(bindingsSelected.bindingParams?.MTS_BANK_CARD_TYPE, creditCardTypes) > -1
  const recipientPhone = values.recipientPhone.replace(/\D/g, '')
  const amount = toPenny(values.amount)
  const isFeeNoticeAvailable = Boolean(transfer.feeAmount && values.recipientBank.value === SBER_ID)

  const isDisabled = !isValid || !dirty || inProgressCheckTransfer || !isEmpty(errorCheckTransfer) || isEmpty(transfer)
  const maxAmount = transferByPhoneConstraints?.maxAmount || DEFAULT_LIMITS.maxAmount
  const maxMessageLength = transferByPhoneConstraints?.maxMessageLength || DEFAULT_LIMITS.maxMessageLength
  const prevValuesGtm = useRef({ ...values })

  const dispatchFetchTransferList = useCallback(() => {
    dispatch(
      fetchTransferList({
        senderPhone: userData.phone_number,
        recipientPhone: `+${recipientPhone}`,
      })
    )
      .then(unwrapResult)
      .then((response: GetTransferListRes) => {
        const recommendedBank = getRecommendedBank(response)

        setFieldValue(formFields.recipientBank.name, recommendedBank)
        setFieldTouched(formFields.recipientBank.name, true)
      })
      .catch(({ error }: ErrorResponse) => {
        if (error?.code === 3011) {
          return setAntifrodPopup({ errorCode: error.code as AntifrodeErrorCodes, message: error?.message })
        }

        toast('error', error?.message, '', {
          withClose: true,
          buttons: (id) => <ToastButton toastId={id} handler={dispatchFetchTransferList} />,
        })
      })
  }, [
    dispatch,
    formFields.recipientBank.name,
    recipientPhone,
    setFieldTouched,
    setFieldValue,
    toast,
    userData.phone_number,
  ])

  // todo: ОБЬЕДИНИТЬ  debouncedFetchTransferList & dispatchFetchTransferList в одну функцию
  // чтоб с пропсом срабатывал уже debouncedFetchTransferList: fetchTransferList(delay)
  const debouncedFetchTransferList = useMemo(
    () =>
      debounce(TRANSFER_LIST_DELAY, () => {
        dispatchFetchTransferList()
      }),
    [dispatchFetchTransferList]
  )

  const debouncedCheckTransfer = useMemo(
    () =>
      debounce(CHECK_TRANSFER_DELAY, (params: ParamsFetchCheckTransfer) => {
        setCheckTransferToast(undefined)
        dispatch(fetchCheckTransfer(params))
          .then(unwrapResult)
          .then(({ transfer }) => {
            dispatch(setIsTransferTypeSBP(transfer.transferType === SBP_TRANSFER_TYPE))
            const error = validateAmountWithFee(transfer.totalAmount, bindingsSelected.balance)

            if (error) {
              setFieldError(formFields.amount.name, error)
            }
          })
          .catch(({ error }: ErrorResponse) => {
            if (error?.code === 3010 || error?.code === 3012) {
              return setAntifrodPopup({
                errorCode: error.code as AntifrodeErrorCodes,
                message: error?.message,
              })
            }

            if (!params.signal.aborted) {
              setCheckTransferToast({ message: error?.message, fn: () => debouncedCheckTransfer(params) })
            }
          })
      }),
    [bindingsSelected.balance, dispatch, formFields.amount.name, setFieldError]
  )

  useEffect(() => {
    setFormData({
      ...values,
      recipientBankName: values.recipientBank.label,
      recipientFIO: transferList?.mtsBankClientPAM,
      fee: transfer.feeAmount,
    })
  }, [values, setFormData, transferList.sbpBanksList, transferList?.mtsBankClientPAM, transfer.feeAmount])

  useEffect(() => {
    formFields.userMessage.validators = formFields.userMessage.validators.map((validator: Validator) => {
      const { length } = validator

      if (typeof length === 'object') {
        length.max = maxMessageLength
      }

      return validator
    })
  }, [formFields.userMessage, maxMessageLength])

  useEffect(() => {
    formFields.amount.validators = updateAmountValidator(formFields.amount.validators, {
      max: maxAmount,
      balance: toPenny(bindingsSelected.balance),
    })
  }, [bindingsSelected.balance, formFields.amount, maxAmount])

  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController

    if (dirty && !Object.keys(errors).length) {
      dispatch(runCheckTransfer())

      const checkTransferParams: ParamsFetchCheckTransfer = {
        signal,
        ...(values.recipientBank.value !== MTS_BANK_ID && { recipientBankId: bankSelected.bankId }),
        amount: toPenny(values.amount),
        bindingId: bindingsSelected.bindingId,
        recipientBankId: bankSelected.bankId,
        recipientBankName: bankSelected.bankName,
        cardExpiry: bindingsSelected.expiry,
        hashedPAN: bindingsSelected.bindingParams?.MTS_BANK_CARD_PAN_HASH,
        isInternalTransfer: values.recipientBank.value === MTS_BANK_ID,
        recipientPhone: `+${recipientPhone}`,
        senderPhone: userData.phone_number,
        userMessage: values.userMessage,
      }

      debouncedCheckTransfer(checkTransferParams)
    } else {
      debouncedCheckTransfer.cancel()
    }

    return () => {
      abortController.abort()
    }
  }, [
    bankSelected.bankId,
    bankSelected.bankName,
    bindingsSelected.bindingId,
    bindingsSelected.bindingParams?.MTS_BANK_CARD_PAN_HASH,
    bindingsSelected.expiry,
    debouncedCheckTransfer,
    dirty,
    dispatch,
    errors,
    recipientPhone,
    userData.phone_number,
    values.amount,
    values.recipientBank.value,
    values.userMessage,
  ])

  const handleChange = useCallback(
    (fieldName: string, value: string | number | BankSuggestion) => {
      setFieldValue(fieldName, value)
      setFieldTouched(fieldName, true, false)
    },
    [setFieldTouched, setFieldValue]
  )

  const handleClearField = useCallback(
    (fieldName: string) => {
      setFieldValue(fieldName, '')
      sendFieldGtm(EventFieldNames.CLEAR_GTM, fieldName)
    },
    [setFieldValue]
  )

  const handleClick = useCallback((fieldName: string) => {
    sendFieldGtm(EventFieldNames.SELECT_GTM, fieldName)
  }, [])

  const handleBlur = useCallback(
    (fieldName: string) => {
      // statistic
      const error: string = errors[fieldName]
      const value: string = values[fieldName]

      if (!isEqual(prevValuesGtm.current[fieldName], value)) {
        if (error) {
          sendFieldGtm(EventFieldNames.SHOW_ERROR_GTM, fieldName, error)
        } else {
          sendFieldGtm(EventFieldNames.SUCCESS_FILLED_GTM, fieldName, prepareInputValueGtm(fieldName, values))
        }
      }
      prevValuesGtm.current = { ...values }
    },
    [errors, values]
  )

  const isHintAmount =
    !errors[formFields.amount.name] || (!touched[formFields.amount.name] && errors[formFields.amount.name])

  const hintAmount =
    isHintAmount &&
    `От ${formatMoney(convertPennyToBanknote(AMOUNT_MIN_VALUE), defaultCurrencyAlias)} до ${formatMoney(
      convertPennyToBanknote(maxAmount),
      defaultCurrencyAlias
    )}`

  const getFee = () => {
    if (!transfer?.issuerFee && isCreditCard) {
      return 0
    }

    return convertPennyToBanknote(transfer.feeAmount)
  }

  return (
    <Form>
      <Heading h={1} size="lg" sizemob="md">
        По номеру телефона
      </Heading>
      <Content>
        <BindingsFieldHandler formFields={formFields} onClick={() => handleClick(formFields.paymentSource.name)} />
        <Content marginTop={theme.spacings.sm}>
          <RecipientPhoneField
            formFields={formFields}
            onChange={(value: string) => handleChange(formFields.recipientPhone.name, value)}
            onBlur={() => handleBlur(formFields.recipientPhone.name)}
            debouncedFetchTransferList={debouncedFetchTransferList}
          />
        </Content>
        <Content marginTop={theme.spacings.sm}>
          <RecipientBankField
            {...formFields.recipientBank}
            onChange={(value: BankSuggestion) => handleChange(formFields.recipientBank.name, value)}
            onClick={() => handleClick(formFields.recipientBank.name)}
            onBlur={() => handleBlur(formFields.recipientBank.name)}
          />
        </Content>
        <Content marginTop={theme.spacings.sm}>
          <Field
            {...formFields.userMessage}
            component={TextField}
            hint={`${values.userMessage.length}/${maxMessageLength}`}
            validate={(value: string) => validateField(value, formFields.userMessage.validators)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(formFields.userMessage.name, event.target.value)
            }
            onClear={() => handleClearField(formFields.userMessage.name)}
            onBlur={() => handleBlur(formFields.userMessage.name)}
          />
        </Content>
        <Content marginTop={theme.spacings.sm}>
          <Field
            {...formFields.amount}
            component={MoneyInputField}
            validate={(value: string) => validateField(toPenny(value), formFields.amount.validators)}
            onChange={(value: string) => handleChange(formFields.amount.name, value)}
            hint={hintAmount}
            onClear={() => handleClearField(formFields.amount.name)}
            onBlur={() => handleBlur(formFields.amount.name)}
          />
        </Content>
        <Content.Margin top={theme.spacings.lg} mobTop={theme.spacings.md}>
          {/* todo  необходимо отрефакторить компонент и пересмотреть набор пропсов, скорее всего часть параметров избыточна, часть возможно уже не актуальна */}
          <FeeAndSum
            base={convertPennyToBanknote(amount)}
            fee={getFee()}
            issuerFee={convertPennyToBanknote(transfer?.issuerFee)}
            inProgress={!isNumber(transfer.feeAmount) || inProgressCheckTransfer}
            isInitialProgress={!isValid || !dirty || !isEmpty(errorCheckTransfer)}
          />
        </Content.Margin>

        {isFeeNoticeAvailable && <FeeNotice />}

        <Content marginTop={theme.spacings.sm}>
          <Button fluidmob size="lg" disabled={isDisabled} onClick={continueBtnClickGtm}>
            Продолжить
          </Button>
        </Content>
      </Content>

      <ModalMapping
        // добавить nonClosable чтобы убрался крест
        isOpened={Boolean(antifrodPopup)}
        modalName={ModalNames.ANTIFROD_ERROR}
        setModal={setAntifrodPopup}
        errorCode={antifrodPopup ? antifrodPopup.errorCode : undefined}
        message={antifrodPopup && antifrodPopup.message}
      />
      {checkTransferToast && (
        <ErrorToastReq
          withClose
          onClick={checkTransferToast.fn}
          title={checkTransferToast.message}
          variant="primary"
          message={null}
        />
      )}
    </Form>
  )
}
